import React from "react";
import SingleMetricSettings from "./SingleMetricSettings";
import { Metric } from "../../models/chart";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import {
  DateDisplayFormatter,
  DisplayDateForPayload,
  calComparedDate,
} from "../Charts/ChartUtils";
import { ReactComponent as CalendarIcon } from "../../Assets/Icons/boxless-calendar.svg";
import DateRangeModal from "../Modal/DateRangeModal";
import { useSelector } from "react-redux";

export default function EditChartType4({
  renderNew,
  handleAllChartsForceReload,
  editDrawerState,
  currentMetricState,
  setCurrentMetricState,
  channel,
  accountCols,
  channelCols,
}) {
  const { chart, handleChartChange } = editDrawerState;
  const [leftMetrics, setLeftMetrics] = React.useState(
    chart.leftMetrics.concat([null])
  );
  const [rightMetrics, setRightMetrics] = React.useState(
    chart.rightMetrics?.concat([null])
  );
  const dashboardDateRange = useSelector(state => state.dashboard.dateRange);
  const [dateRange, setDateRange] = React.useState(chart.compareWith?.startsWith("Previous")
    ? calComparedDate(chart.compareWith, dashboardDateRange)
    : {
      label: "Custom date range",
      startDate: chart.compareWith?.split("_")[0],
      endDate: chart.compareWith?.split("_")[1],
    }
  );
  const [table, setTable] = React.useState(chart.table);
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  const state = useSelector((state) => channel.getState(state));
  const baseMetrics = channel.isBlend
    ? channelCols.metrics
    : Array.isArray(state.metrics)
      ? state.metrics
      : state.metrics?.[table?.id + (chart.dataLevel ? `:${chart.dataLevel}` : "")] ??
      [];
  const customMetrics = accountCols
    ? accountCols.metrics
    : Array.isArray(state.customMetrics)
      ? state.customMetrics
      : state.customMetrics?.[
      table?.id + (chart.dataLevel ? `:${chart.dataLevel}` : "")
      ] ?? [];
  const metrics = React.useMemo(() => {
    let metrics = baseMetrics.concat(customMetrics);
    metrics = [...new Map(metrics.map(metric => [metric.id, metric])).values()];
    return metrics;
  },[customMetrics,baseMetrics]);

  const applyDate = (datePickerRange) => {
    setDateRange(datePickerRange);
    setIsDatePickerOpen(false);
    if (datePickerRange.startDate && datePickerRange.endDate) {
      handleChartChange(
        "compareWith",
        datePickerRange.label === "Custom date range"
          ? (DisplayDateForPayload(datePickerRange?.startDate) + "_" + DisplayDateForPayload(datePickerRange?.endDate))
          : datePickerRange.label
      );
    } else {
      handleChartChange("compareWith", "");
    }
  };

  if (!currentMetricState.metric.channelType) {
    currentMetricState.metric.channelType = channel.type;
    currentMetricState.metric.validate();
  }

  React.useEffect(() => {
    if (table !== chart.table) {
      handleChartChange("table", table);
      setLeftMetrics([null]);
      setRightMetrics(chart.rightMetrics ? [null] : null);
    }
  }, [table]);

  React.useEffect(() => {
    handleChartChange(
      "leftMetrics",
      leftMetrics.filter((metric) => metric && metric.metric)
    );
  }, [leftMetrics]);

  React.useEffect(() => {
    setCurrentMetricState({
      ...currentMetricState,
      metric: leftMetrics[0] ?? Metric.new(chart),
    });
  }, [renderNew]);

  React.useEffect(() => {
    handleChartChange(
      "rightMetrics",
      rightMetrics?.filter((metric) => metric && metric.metric)
    );
  }, [rightMetrics]);

  React.useEffect(() => {
    // setLeftMetrics(leftMetrics.filter(metric => !metric || metrics?.findIndex(m => m.id === metric?.metric?.id) > -1));
    // setRightMetrics(rightMetrics?.filter(metric => !metric || metrics?.findIndex(m => m.id === metric?.metric?.id) > -1));
  }, [accountCols])

  const updateFilter = (filter) => {
    if (currentMetricState.axis === "left") {
      const newMetrics = Array.from(leftMetrics);
      const metric = Metric.copy(newMetrics[currentMetricState.index]);
      metric.filter = filter;
      newMetrics[currentMetricState.index] = metric;
      setLeftMetrics(newMetrics);
      setCurrentMetricState({ ...currentMetricState, metric: metric });
    } else {
      const newMetrics = Array.from(rightMetrics);
      const metric = Metric.copy(newMetrics[currentMetricState.index]);
      metric.filter = filter;
      newMetrics[currentMetricState.index] = metric;
      setRightMetrics(newMetrics);
      setCurrentMetricState({ ...currentMetricState, metric: metric });
    }
  };

  return (
    <div>
      {/* {editDrawerState && (
        <Accordion
          sx={{
            boxShadow: "none",
            "& .Mui-expanded": { marginBottom: "0px" },
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <AccordionSummary
            sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              Compare with
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "10px 0px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #EAEAEC",
                width: "100%",
                borderRadius: "6px",
                padding: "0px 16px",
                cursor: "pointer",
              }}
              onClick={() => setIsDatePickerOpen(true)}
            >
              <IconButton
                disableRipple
                onClick={() => setIsDatePickerOpen(true)}
                aria-label="delete"
              >
                <CalendarIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                style={{ lineHeight: "16px", marginLeft: "10px" }}
              >
                {/* <span className="inter" style={{ color: "#858585", fontSize: 10 }}>
                                        {dateRange?.label ?? (dateRange?.startDate && dateRange?.endDate ? "Custom Date Range" : "")}
                                    </span>
                                    <br /> *}
                <span
                  className="inter"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#585969",
                  }}
                >
                  {dateRange?.startDate && dateRange?.endDate
                    ? `${DateDisplayFormatter(dateRange?.startDate)} -${" "}
                                        ${DateDisplayFormatter(
                      dateRange?.endDate
                    )}`
                    : "No Comparison"}
                </span>
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      )} */}
      <SingleMetricSettings
        chart={chart}
        channel={channel}
        accountCols={accountCols}
        channelCols={channelCols}
        currentMetric={currentMetricState.metric}
        isKPI={chart.type === "KPI"}
        handleChartChange={handleChartChange}
        onChange={(metric) => {
          if (currentMetricState.axis === "left") {
            const newMetrics = Array.from(leftMetrics);
            newMetrics[currentMetricState.index] = metric;
            if (metric && currentMetricState.index === newMetrics.length - 1) {
              newMetrics.push(null);
            }
            setLeftMetrics(newMetrics);
          } else {
            const newMetrics = Array.from(rightMetrics);
            newMetrics[currentMetricState.index] = metric;
            if (metric && currentMetricState.index === newMetrics.length - 1) {
              newMetrics.push(null);
            }
            setRightMetrics(newMetrics);
          }
          setCurrentMetricState({ ...currentMetricState, metric: metric });
        }}
        updateFilter={updateFilter}
        handleAllChartsForceReload={handleAllChartsForceReload}
        onClose={() =>
          setCurrentMetricState({ ...currentMetricState, isOpen: false })
        }
      />
      <DateRangeModal
        compare={true}
        dateRange={dashboardDateRange}
        compareDateRange={dateRange}
        changeCompareDate={applyDate}
        isDatePickerOpen={isDatePickerOpen}
        setIsDatePickerOpen={setIsDatePickerOpen}
      />
    </div>
  );
}
