import { Box, Button, Modal, TextField, } from "@mui/material";
import "../../../styles/filterpopup.scss";
import React from "react";
import SegmentBox from "./SegmentBox";
import FilterBox from "./FilterBox";
import { Filter, NEW_FILTER } from "../../../models/chart";
import { useSelector } from "react-redux";
import { GetChannel } from "../../../models/channel";
import { transformToFilterDataObject } from "../Utils";
import { createFilter, updateFilter } from "../../../api/filter/saga";
import AccountsBox from "./AccountsBox";
import { getBlendColumns } from "../../../api/blends/saga";
import Loader from "../../Common/Loader";
import DataBlendIcon from "../../../Assets/Icons/DataBlend.svg";
import FailureModal from "../../Modal/FailureModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "82vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: "0px 32px 48px -16px rgba(0, 0, 0, 0.10)",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  p: 4,
  fontSize: "16px"
};

export default function FilterPopup({ accounts, accountCols, blendChannelCols, chart, chartFilter, currentFilter, onChange, onClose, extractor }) {
  const [filterMsg, setFilterMsg] = React.useState({ message: "none", error: "none", operation: "none" });
  const nameRef = React.useRef(currentFilter.name);
  const [currAccount, setCurrAccount] = React.useState(currentFilter.account ?? accounts[0]);
  const segmentRef = React.useRef(currentFilter.segment);
  const dimensionFiltersRef = React.useRef(currentFilter.dimensionFilters.length === 0
    ? [NEW_FILTER]
    : (currentFilter.dimensionFilters[currentFilter.dimensionFilters.length - 1].boolOperator
      ? currentFilter.dimensionFilters.concat(NEW_FILTER)
      : currentFilter.dimensionFilters)
  );
  const metricFiltersRef = React.useRef(currentFilter.metricFilters?.length === 0 ? [NEW_FILTER] : (currentFilter.metricFilters[currentFilter.metricFilters.length - 1]?.boolOperator ? currentFilter.metricFilters.concat(NEW_FILTER) : currentFilter.metricFilters));
  const [nameError, setNameError] = React.useState(false);
  const [accountError, setAccountError] = React.useState(false);
  const [channelCols, setChannelCols] = React.useState(blendChannelCols ?? { metrics: [], dimensions: [] });
  const blendsState = useSelector(state => state.blends);

  const channel = GetChannel(currentFilter.channelType);
  const state = useSelector(state => channel.getState(state));
  const segments = state.segments;

  const baseMetrics = channel.isBlend ? channelCols.metrics : Array.isArray(state.metrics) ? state.metrics : state.metrics?.[chart?.table?.id + (chart?.dataLevel ? `:${chart?.dataLevel}` : "")] ?? [];
  const baseDimensions = channel.isBlend ? channelCols.dimensions : Array.isArray(state.dimensions) ? state.dimensions : state.dimensions?.[chart?.table?.id + (chart?.dataLevel ? `:${chart?.dataLevel}` : "")] ?? [];

  const customMetrics = (accounts ? accountCols[currAccount?.title]?.metrics
    : (accountCols ? accountCols.metrics : Array.isArray(state.customMetrics) ? state.customMetrics : state.customMetrics?.[chart?.table?.id + (chart?.dataLevel ? `:${chart?.dataLevel}` : "")])
  ) ?? [];
  const customDimensions = (accounts ? accountCols[currAccount?.title]?.dimensions
    : (accountCols ? accountCols.dimensions : Array.isArray(state.customDimensions) ? state.customDimensions : state.customDimensions?.[chart?.table?.id + (chart?.dataLevel ? `:${chart?.dataLevel}` : "")])
  ) ?? [];

  const metrics = baseMetrics.concat(customMetrics);
  const dimensions = baseDimensions.concat(customDimensions);

  const dimensionsBing = dimensions.filter(f => f.filterable);

  const saveFilter = (filter, account) => {
    if (extractor) {
      const filterObject = transformToFilterDataObject({ ...filter, tableId: chart?.table?.id })
      onChange({ ...filterObject, account })
      onClose();
    } else {
      if (!filter.isEqualAll(currentFilter)) {
        const filterObject = transformToFilterDataObject({ ...filter, account, tableId: chart?.table?.id ? chart?.table?.id + (chart?.dataLevel ? " - " + chart?.dataLevel : "") : null })
        if (!currentFilter.name) {
          createFilter(filterObject).then(filterId => {
            filter.id = filterId;
            onChange(filter);
            onClose();
          }).catch((error) => {
            callback("Fail", error?.data?.err ?? "");
          });
        } else {
          updateFilter(filterObject).then(data => {
            onChange(filter, true);
            onClose();
          }).catch((error) => {
            callback("Fail", error?.data?.err ?? "", "Updation");
          });
        }
      } else if (chartFilter && !filter.isEqualAll(chartFilter)) {
        onChange(filter);
        onClose();
      }
    }
  }

  const callback = (msg, err, type = "Creation") => {
    setFilterMsg({
      error: err?.trim() === "Duplicate Name!" ? "A filter with this name already exists for this ad account." : err,
      message: msg,
      operation: type
    });
  }

  React.useEffect(() => {
    setAccountError(false);
  }, [currAccount])

  const [loader, setLoader] = React.useState(false);

  const updateBlendCols = () => {
    if (channel.isBlend) {
      setLoader(true);
      getBlendColumns(channel.type).then(data => {
        let columns = (data?.columns ?? []).reduce((columns, column) => ({
          ...columns,
          [column.type.toLowerCase() + 's']: (columns[column.type.toLowerCase() + 's'] ?? [])
            .concat({
              ...column, name: column.uiName ?? column.name,
              icon: GetChannel(column.dataSource)?.icon ?? DataBlendIcon
            })
        }), { dimensions: [], metrics: blendsState.blendCustomCols[channel.type] ?? [] });
        setChannelCols(columns);
      }).catch(err => {
        setChannelCols({ metrics: [], dimensions: [] })
      }).finally(() => {
        setLoader(false)
      })
    } else {
      setChannelCols({ metrics: [], dimensions: [] })
    }
  }

  React.useEffect(() => {
    if (!blendChannelCols) {
      updateBlendCols();
    }
  }, [blendChannelCols])

  return (
    <div>
      <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "24px"
              }}>
              <img style={{ border: "1px solid #E0E3E5", borderRadius: "12px", padding: "8px" }}
                height="48px" width="48px" src={channel.icon} alt={channel.title} />
              <TextField
                className="filter-name"
                sx={{ marginLeft: "12px" }}
                variant="standard"
                placeholder="Filter name*"
                defaultValue={nameRef.current}
                error={nameError}
                label={nameError ? "Required" : ""}
                required
                onChange={e => { nameRef.current = e.target.value; setNameError(nameRef.current.length === 0); }}
                InputProps={{ disableUnderline: true }}
              />
            </Box>
            {(accounts || currAccount || channel.isBlend) &&
              <AccountsBox accounts={accounts}
                blend={channel.isBlend ? state.audiences?.map((audience) => {
                  const channel = GetChannel(audience?.[0]?.dataSource);
                  let metaData = audience?.[0]?.metaData ?? {};
                  return ({
                    id: metaData.id,
                    name: metaData.title,
                    subtitle: metaData.id + (metaData.subTitle ? ", " + metaData.subTitle : ""),
                    icon: channel?.icon
                  })
                }) : null}
                currAccount={currAccount} setCurrAccount={setCurrAccount}
                accountError={accountError} dimensionFiltersRef={dimensionFiltersRef}
              />
            }
            {segments && <SegmentBox channel={channel} segments={segments} segmentRef={segmentRef} />}
            {loader ? <Loader /> : <FilterBox
              key={currAccount?.title}
              title={"Column Filter"}
              channel={channel}
              dimensions={dimensions.map(dim => ({ ...dim, type: "DIMENSION" }))}
              metrics={metrics.map(metric => ({ ...metric, type: "METRIC" }))}
              filterListRef={dimensionFiltersRef}
            />}
            <br />
            {/* {
              channel.type === ChannelType.GoogleAds && <FilterBox
                title="Metric Filter"
                channel={channel}
                filters={metrics}
                filterListRef={metricFiltersRef}
              />
            } */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}>
              <Button
                sx={{ marginRight: "10px", textTransform: "none" }}
                onClick={onClose}>
                Cancel
              </Button>
              <Button sx={{ textTransform: "none", height: "40px" }} onClick={() => {
                if (nameRef.current.length === 0) { setNameError(true); return; }
                if (accounts?.length > 0 && !currAccount) { setAccountError(true); return; }
                const filter = Filter.new(
                  chart,
                  nameRef.current,
                  segmentRef.current,
                  dimensionFiltersRef.current.filter(filter => filter.filter && filter.operator && (filter.value && (!Array.isArray(filter.value) || filter.value[0]))),
                  metricFiltersRef.current.filter(filter => filter.filter && filter.operator && filter.value),
                  currentFilter.id
                );
                if (filter.isEmpty()) { alert("Filter is empty"); return; }
                saveFilter(filter, currAccount);
              }} variant="contained">
                {chart.reportFilter ?
                  (!currentFilter.name ? "Save Filter" : "Update and Save Filter") :
                  (!currentFilter.name ? "Save and Apply Filter" : "Update and Apply Filter")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <FailureModal
        modal={filterMsg.message === "Fail"}
        setModal={() => setFilterMsg({ message: "none", error: "none", operation: "none" })}
        message={filterMsg?.error?.length > 0 ? "" : `Filter ${filterMsg.operation} Failed`}
        error={filterMsg.error}
      />
    </div>
  );
};
