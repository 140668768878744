import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Modal,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import "react-calendar/dist/Calendar.css";
import "../styles/calander.css";
import ListItemButton from "@mui/material/ListItemButton";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Calendar } from "react-multi-date-picker";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import CustomSwitch from "../Common/CustomSwitch";
import { ReactComponent as CalenderNewIcon } from "../../Assets/Icons/calender-borderless.svg";
import { ReactComponent as GlobeIcon } from "../../Assets/Icons/globe.svg";
import { capitalizeString } from "../CustomizingSources/Utils";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";

const lastWeekStartDate = (count) => {
  const startDate = new Date(moment().subtract(count, 'week').startOf('week'));
  return startDate.setDate(startDate.getDate() + 1);
}
const lastWeekEndDate = () => {
  const endDate = new Date(moment().subtract(1, 'week').endOf('week'));
  return endDate.setDate(endDate.getDate() + 1);
}

const DateRangeModal = ({
  dateRange,
  setDateRange,
  compareDateRange,
  changeCompareDate,
  isDatePickerOpen,
  setIsDatePickerOpen,
  commonUpdateHandler,
  compare = false,
  other = null,
  shared
}) => {
  const [datePickerRange, setDatePickerRange] = useState({
    label: dateRange.label ?? dateRange.dateType ?? "",
    startDate: dateRange.startDate ?? dateRange.fromDate,
    endDate: dateRange.endDate ?? dateRange.toDate,
  });
  const [compareDatePickerRange, setCompareDatePickerRange] = useState({
    label: (compareDateRange?.label ?? compareDateRange?.dateType) || "Custom date range",
    startDate: compareDateRange?.startDate ?? compareDateRange?.fromDate,
    endDate: compareDateRange?.endDate ?? compareDateRange?.toDate,
  });
  const [isTillTodaySelected, setTodaySelected] = useState(false);
  const [compared, setCompared] = useState((compareDateRange?.label || compareDateRange?.dateType || compare) ? true : false);
  const [selectCompared, setSelectCompared] = useState(compare);

  const applyDate = (range, compareRange) => {
    if (commonUpdateHandler) {
      commonUpdateHandler({
        ...range,
        startDate: range.startDate ? formatDate(range.startDate) : range.startDate,
        endDate: range.endDate ? formatDate(range.endDate) : range.endDate
      }, {
        ...compareRange,
        label: compared ? compareRange.label : "",
        startDate: compareRange.startDate ? formatDate(compareRange.startDate) : compareRange.startDate,
        endDate: compareRange.endDate ? formatDate(compareRange.endDate) : compareRange.endDate
      })
    }
    else {
      if (!compare) {
        setDateRange({
          ...range,
          startDate: range.startDate ? formatDate(range.startDate) : range.startDate,
          endDate: range.endDate ? formatDate(range.endDate) : range.endDate
        });
      }
      if (compareRange && changeCompareDate) {
        changeCompareDate({
          ...compareRange,
          label: compared ? compareRange.label : "",
          startDate: compareRange.startDate ? formatDate(compareRange.startDate) : compareRange.startDate,
          endDate: compareRange.endDate ? formatDate(compareRange.endDate) : compareRange.endDate
        });
      }
    }
    setIsDatePickerOpen(false);
  };

  const [count, setCount] = useState(1);
  const [compareCount, setCompareCount] = useState(1);
  const [currentDate, setCurrentDate] = useState("Weeks");
  const [comparedCurrentDate, setComparedCurrentDate] = useState("Weeks");
  const [activeList, setActiveList] = useState(null);
  const [compareActiveList, setCompareActiveList] = useState(null);

  const today = new Date();
  var first = today.getDate() - today.getDay() + 1; // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6

  const definedDateRanges = [
    {
      startDate: today,
      endDate: today,
      label: "Today"
    },
    {
      startDate: new Date().setDate(today.getDate() - 1),
      endDate: new Date().setDate(today.getDate() - 1),
      label: "Yesterday"
    },
    {
      startDate: new Date().setDate(first),
      endDate: new Date().setDate(today.getDate() - 1) < new Date().setDate(first) ? new Date().setDate(first) : new Date().setDate(today.getDate() - 1),
      label: "This Week"
    },
    {
      startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      endDate: new Date().setDate(today.getDate() - 1) < new Date(today.getFullYear(), today.getMonth(), 1) ? new Date(today.getFullYear(), today.getMonth(), 1) : new Date().setDate(today.getDate() - 1),
      label: "This Month"
    },
    {
      startDate: new Date(today.getFullYear(), 0, 1),
      endDate: new Date().setDate(today.getDate() - 1) < new Date(today.getFullYear(), 0, 1) ? new Date(today.getFullYear(), 0, 1) : new Date().setDate(today.getDate() - 1),
      label: "This Year"
    },
  ];

  let diffTime = Math.floor(Math.abs((new Date(datePickerRange.endDate) - new Date(datePickerRange.startDate)) / (1000 * 60 * 60 * 24))) + 1;
  const definedCompareDateRanges = [
    {
      startDate: new Date(datePickerRange.startDate).setDate(new Date(datePickerRange.startDate).getDate() - diffTime),
      endDate: new Date(datePickerRange.endDate).setDate(new Date(datePickerRange.endDate).getDate() - diffTime),
      label: "Previous Period"
    },
  ];

  const handleCompareCustomDate = (type, count) => {
    setComparedCurrentDate(type);
    let startDate = new Date(datePickerRange.startDate);
    let endDate = new Date(datePickerRange.endDate);
    setCompareDatePickerRange({
      label: `Previous ${count} ${type}`,
      startDate: moment(startDate, "YYYY-MM-DD").subtract(count, type).format("YYYY-MM-DD"),
      endDate: moment(endDate, "YYYY-MM-DD").subtract(count, type).format("YYYY-MM-DD")
    })
  };

  const handleCustomDate = (type, count, isTillTodaySelected) => {
    setCurrentDate(type);
    let localDate = {}
    switch (type) {
      default:
      case "Days":
        localDate = {
          label: `Last ${count} Days`,
          startDate: new Date(new Date().setDate(today.getDate() - count)),
          endDate: isTillTodaySelected ? tillToday() : new Date(new Date().setDate(today.getDate() - 1)),
        };
        break;
      case "Months":
        localDate = {
          label: `Last ${count} Months`,
          startDate: new Date(today.getFullYear(), today.getMonth() - count, 1),
          endDate: isTillTodaySelected ? tillToday() : new Date(today.getFullYear(), today.getMonth(), 0),
        };
        break;
      case "Years":
        localDate = {
          label: `Last ${count} Years`,
          startDate: new Date(today.getFullYear() - count, 0, 1),
          endDate: isTillTodaySelected ? tillToday() : new Date(today.getFullYear() - 1, 11, 31),
        };
        break;
      case "Weeks":
        var startDate = lastWeekStartDate(count);
        var endDate = lastWeekEndDate();
        localDate = {
          label: `Last ${count} Weeks`,
          startDate: startDate,
          endDate: isTillTodaySelected ? tillToday() : endDate,
        };
        break;
    }
    if (isTillTodaySelected && !localDate.label?.toLowerCase().includes("including today")) {
      localDate = { ...localDate, "label": localDate.label + " Including Today" };
    }
    setDatePickerRange(localDate);
  };

  const updateCustomDate = (startDate, endDate) => {
    const findDate = definedDateRanges.find((date) => formatDate(startDate) === formatDate(date.startDate) && formatDate(endDate) === formatDate(date.endDate));
    findDate && setActiveList(findDate.label);
    return findDate?.label;
  };

  const formatDate = (date) => date ? moment(date).format("YYYY-MM-DD") : "YYYY-MM-DD";
  const definedDateRangesDropdown = ["Days", "Weeks", "Months", "Years"];

  useEffect(() => {
    const isLabelDateType = dateRange.label ?? dateRange.dateType ?? "";
    let start = datePickerRange.startDate ?? dateRange.fromDate;
    let end = datePickerRange.endDate ?? dateRange.toDate;
    const isTodayEnabled = datePickerRange.label?.toLowerCase()?.includes("including today");
    let isHandleCustomHit = false;
    let selectedActiveTab = "";
    if (datePickerRange.label) {
      if (isLabelDateType.startsWith("Last")) {
        let [_, num, period, nextNum] = isLabelDateType.split(" ");
        let numberRegex = /\d+/;
        let isNumberThere = numberRegex.exec(nextNum);
        num = Number(num) || isNumberThere?.[0] || 1;
        period = capitalizeString(period).trim();
        setActiveList(`Last ${num} ${period}`);
        setCount(num);
        setCurrentDate(period);
        !compare && handleCustomDate(period, num, isTodayEnabled);
        isHandleCustomHit = true;
      } else {
        const isThere = { ...definedDateRanges.find((range) => isLabelDateType?.toLowerCase()?.startsWith(range.label?.toLowerCase())) };
        if (Object.keys(isThere).length) {
          start = isThere.startDate;
          end = isThere.endDate;
          selectedActiveTab = isThere?.label;
        }
      }
    }
    if (!isHandleCustomHit && !compare) {
      if (datePickerRange?.label?.toLowerCase()?.includes('including today') && selectedActiveTab) {
        setActiveList(selectedActiveTab || "Custom date range");
      } else {
        setActiveList(datePickerRange.label || "Custom date range");
      }
      setDatePickerRange({
        label: dateRange.label ?? dateRange.dateType ?? "",
        startDate: start ?? dateRange.fromDate,
        endDate: isTodayEnabled ? tillToday() : end
      });
    }
    setTodaySelected(isTodayEnabled);

    setCompareActiveList(compareDatePickerRange.label || "Custom date range");
    if (compareDatePickerRange.label.startsWith("Previous") && compareDatePickerRange.label !== "Previous Period") {
      const [_, num, period] = compareDatePickerRange.label.split(" ");
      setCompareCount(Number(num) ?? 1);
      setComparedCurrentDate(period);
    }
  }, []);

  const tillToday = () => {
    return formatDate(new Date());
  };

  const removeTillToday = (type) => {
    const isThere = { ...definedDateRanges.find((range) => type?.toLowerCase()?.startsWith(range.label?.toLowerCase())) };
    if (type.toLowerCase() === "custom date range including today") {
      setDatePickerRange({ ...datePickerRange, label: "Custom Date Range", endDate: null });
    } else if (Object.keys(isThere).length) {
      setDatePickerRange(isThere);
    } else {
      handleCustomDate(currentDate, count, false);
    }
  };

  useEffect(() => {
    setTodaySelected(datePickerRange.label?.toLowerCase()?.includes("including today"));
    if (compareDatePickerRange.label === "Previous Period") {
      setCompareDatePickerRange(definedCompareDateRanges[0]);
    } else if (compareActiveList?.startsWith("Previous")) {
      handleCompareCustomDate(comparedCurrentDate, compareCount);
    }
  }, [datePickerRange]);

  return (
    <Modal
      style={{
        margin: "auto auto",
        backgroundColor: "#FFFFFF",
      }}
      open={isDatePickerOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onBackdropClick={false}
      className="calanderModal"
    >
      <Box
        style={{
          backgroundColor: "#FFFFFF",
          width: "100%",
          height: "fit-content",
          padding: "8px 15px",
          border: "0.5px solid #E5E5E5",
          boxShadow: "0px 10px 24px rgba(35, 40, 60, 0.05)",
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          className="displayRowSpaced"
          style={{
            color: "#2E3044",
            fontFamily: "Lato",
            fontWeight: 600,
            fontSize: "22px",
            paddingTop: "10px",
            fontStyle: "normal",
            paddingBottom: "10px",
            borderBottom: "1px solid #EAEAEC"
          }}
        >
          Choose the calendar date
          <span className="displayRow inter" style={{ fontSize: "14px", color: "#7D899C" }}>
            <GlobeIcon style={{ marginRight: "5px" }} />{Intl.DateTimeFormat().resolvedOptions().timeZone}
          </span>
        </Typography>

        <div className="displayRowSpaced" style={{ padding: "15px 0px", borderBottom: "1px solid #EAEAEC" }}>
          <div className="displayRowLeft" style={{ gap: "10px" }}>
            <Typography className="displayRowLeft inter pointer"
              onClick={() => !compare && setSelectCompared(false)}
              style={{
                border: !selectCompared ? "1px solid #0869FB" : "1px solid #EAEAEC", borderRadius: "6px", gap: "10px",
                color: "#6D6E7C", fontSize: "12px", padding: "8px 16px",
              }}
            >
              <CalenderNewIcon style={{ width: "18px" }} />
              {`${formatDate(datePickerRange.startDate)} - ${formatDate(
                datePickerRange.endDate
              )}`}
            </Typography>
            {
              compared && <>
                vs
                <Typography className="displayRowLeft inter pointer"
                  onClick={() => setSelectCompared(true)}
                  style={{
                    border: selectCompared ? "1px solid #0869FB" : "1px solid #EAEAEC", borderRadius: "6px", gap: "10px",
                    color: "#6D6E7C", fontSize: "12px", padding: "8px 16px",
                  }}
                >
                  <CalenderNewIcon style={{ width: "18px" }} />
                  {`${formatDate(compareDatePickerRange.startDate)} - ${formatDate(
                    compareDatePickerRange.endDate
                  )}`}
                </Typography>
              </>
            }
          </div>
          <CustomSwitch checked={compared}
            onChange={(e) => {
              if (!compare) {
                setCompared(e.target.checked);
                setSelectCompared(e.target.checked);
                e.target.checked && other?.report_name && handleMixPanelData(EVENTS.compare_calendar_reports, { report_name: other?.report_name });
              }
            }}
          />
        </div>

        <Grid
          container
          sx={{ marginTop: "5px" }}
          direction="row"
          alignItems="center"
          justifyContent="center"
          item
        >
          <Grid xs={8.5} item className={selectCompared ? "compared" : "normal"}>
            <Calendar
              value={selectCompared ?
                [formatDate(compareDatePickerRange.startDate), formatDate(compareDatePickerRange.endDate)]
                : [formatDate(datePickerRange.startDate), formatDate(datePickerRange.endDate)]
              }
              range
              // multiple   // Need to wrap value in one more array if enabled
              numberOfMonths={2}
              onChange={(array) => {
                if (Array.isArray(array[array.length - 1])) {  // Handling for case when multiple enabled
                  let newarray = array[array.length - 1]
                  if (newarray.length === 2) {
                    const arr = newarray.join(",\n").split(",");
                    (selectCompared ? setCompareDatePickerRange : setDatePickerRange)({
                      label: updateCustomDate(arr[0], arr[1].substring(1)) ?? "Custom date range",
                      startDate: arr[0],
                      endDate: arr[1].substring(1)
                    });
                  }
                  (selectCompared ? setCompareActiveList : setActiveList)("Custom date range");
                } else if (array.length === 2) {
                  const arr = array.join(",\n").split(",");
                  (selectCompared ? setCompareDatePickerRange : setDatePickerRange)({
                    label: updateCustomDate(arr[0], arr[1].substring(1)) ?? "Custom date range",
                    startDate: arr[0],
                    endDate: arr[1].substring(1)
                  });
                  (selectCompared ? setCompareActiveList : setActiveList)("Custom date range");
                } else if (array.length === 1) {
                  const arr = array.join(",\n").split(",");
                  (selectCompared ? setCompareDatePickerRange : setDatePickerRange)({
                    label: "Custom date range",
                    startDate: arr[0],
                    endDate: null
                  });
                  (selectCompared ? setCompareActiveList : setActiveList)("Custom date range");
                  setTodaySelected(false);
                }
              }}
              // showOtherDays
              format="YYYY-MM-DD"
              key={(selectCompared ? compareDatePickerRange : datePickerRange).startDate + "-" + (selectCompared ? compareDatePickerRange : datePickerRange).endDate}
            />
          </Grid>
          {/* <Grid xs={0.2} /> */}
          <Grid item xs={3.5} className="list"
            style={{ backgroundColor: "#F8F9FD", borderRadius: "6px", minHeight: "300px", height: "300px", overflow: "scroll" }}
          >
            <div
              key={selectCompared}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
                width: "100%",
              }}
            >
              {(selectCompared ? definedCompareDateRanges : definedDateRanges).map((date, index) => (
                <ListItemButton
                  key={index + "-" + datePickerRange.label}
                  sx={{
                    textAlign: "center",
                    backgroundColor: (date.label === (selectCompared ? compareActiveList : activeList) || (formatDate(date.startDate) === formatDate(datePickerRange.startDate) && formatDate(date.endDate) === formatDate(datePickerRange.endDate))) ? "#FFFFFF" : "",
                    color: "#585969",
                    fontFamily: "Inter",
                    fontSize: "12.5px",
                    marginTop: "10px",
                    width: "90%",
                    borderRadius: "6px",
                    display: "inline-block",
                    padding: "9px 0px",
                  }}
                  onClick={() => {
                    selectCompared ?
                      setCompareDatePickerRange({
                        ...date,
                        startDate: formatDate(date.startDate),
                        endDate: formatDate(date.endDate),
                        label: date.label
                      })
                      : setDatePickerRange({
                        ...date,
                        startDate: formatDate(date.startDate),
                        endDate: isTillTodaySelected ? tillToday() : formatDate(date.endDate),
                        label: isTillTodaySelected ? date.label + " Including Today" : date.label
                      });
                    (selectCompared ? setCompareActiveList : setActiveList)(date.label);
                  }}
                >
                  {date.label}
                </ListItemButton>
              ))}
              <div className="displayColCenter pointer"
                style={{
                  width: "90%", marginTop: "5px", padding: "10px",
                  backgroundColor: (selectCompared ? compareActiveList : activeList)?.endsWith("s") ? "#FFFFFF" : "",
                }}
                onClick={() => {
                  if (selectCompared) {
                    setCompareActiveList(`Previous ${compareCount} ${comparedCurrentDate}`)
                    handleCompareCustomDate(comparedCurrentDate, compareCount)
                  } else {
                    setActiveList(`Last ${count} ${currentDate}`)
                    handleCustomDate(currentDate, count, isTillTodaySelected);
                  }
                }}
              >
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ fontSize: "12px", color: "#585969" }}
                >
                  {selectCompared ? "Previous " : "Last "} N Period
                </Typography>
                {(selectCompared ? compareActiveList : activeList)?.endsWith("s") && <div className="displayRowCenter" style={{ gap: "10px" }}>
                  <div>
                    <Typography
                      variant="h5"
                      component="h5"
                      style={{ fontSize: "12px", color: "#9798A1", margin: "10px 0px 5px" }}
                    >
                      N Value
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      type="number"
                      min="1"
                      max="99"
                      size="small"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        width: "45px",
                        fontSize: "11px",
                      }}
                      className="calanderInputNumber"
                      value={selectCompared ? compareCount : count}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        (selectCompared ? setCompareCount : setCount)(parseInt(e.target.value));
                        if (currentDate || comparedCurrentDate) {
                          selectCompared ?
                            handleCompareCustomDate(comparedCurrentDate, e.target.value) :
                            handleCustomDate(currentDate, e.target.value, isTillTodaySelected);
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="h5"
                      component="h5"
                      style={{ fontSize: "12px", color: "#9798A1", margin: "10px 0px 5px" }}
                    >
                      Time Period
                    </Typography>
                    <FormControl style={{ width: "70px" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectCompared ? comparedCurrentDate : currentDate}
                        label="Age"
                        size="small"
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          width: "80px",
                          fontSize: "11px",
                        }}
                      >
                        {definedDateRangesDropdown.map((label, index) => (
                          <MenuItem
                            value={label}
                            key={index}
                            onClick={(e) => {
                              e.stopPropagation();
                              selectCompared ?
                                handleCompareCustomDate(label, compareCount) :
                                handleCustomDate(label, count, isTillTodaySelected)
                            }}
                          >
                            {label.substring(0, label.length - 1)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>}
              </div>
              <div className="displayColCenter"
                style={{
                  width: "90%", marginTop: "5px",
                  backgroundColor: (selectCompared ? compareActiveList : activeList) === "Custom date range" ? "#FFFFFF" : "",
                }}
              >
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ fontSize: "12px", color: "#585969", margin: "10px" }}
                >
                  Custom Selection
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          sx={{ marginTop: "20px" }}
          justifyContent="space-between"
        >
          {
            (formatDate(datePickerRange.startDate) === compareDatePickerRange.startDate
              && formatDate(datePickerRange.endDate) === compareDatePickerRange.endDate
            ) && <h4 style={{
              color: "red", textAlign: "right", borderRadius: "10px",
              width: "100%", fontSize: "12px", marginTop: "15px"
            }}>
              <b>Both Date ranges cannot be same!</b>
            </h4>
          }
          <Grid item sx={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                disabled={compare}
                checked={isTillTodaySelected}
                onClick={() => {
                  const label = datePickerRange.label;
                  if (isTillTodaySelected) {
                    removeTillToday(activeList ?? label);
                    setTodaySelected(!isTillTodaySelected);
                  } else {
                    setDatePickerRange({ ...datePickerRange, label: datePickerRange.label + " " + "Including Today", endDate: tillToday(datePickerRange.endDate) });
                    setTodaySelected(!isTillTodaySelected);
                  }
                }}
              />
              <Typography
                variant="h5"
                component="h5"
                style={{ fontSize: "12px", color: "#2E3044", fontSize: "14px" }}
              >
                Till Today
              </Typography>
            </div>
          </Grid>
          <Grid item sx={6}>
            {compare && (
              <Button
                onClick={() => applyDate({}, { startDate: "", endDate: "" })}
                variant="text"
                color="primary"
                style={{ textTransform: "capitalize" }}
              >
                No Comparison
              </Button>
            )}
            <Button
              sx={{
                textTransform: "capitalize",
                color: "#0869FB",
                marginRight: "20px",
              }}
              onClick={() => setIsDatePickerOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize", backgroundColor: "#0869FB" }}
              onClick={() => {
                other?.report_name && (shared? handleMixPanelData(EVENTS.apply_calendar_shared_report, { report_name: other?.report_name, calendar_range: datePickerRange }) : handleMixPanelData(EVENTS.update_calendar_report, { report_name: other?.report_name }));
                applyDate(datePickerRange, compared ? compareDatePickerRange : { startDate: "", endDate: "" });
              }}
              disabled={!datePickerRange.startDate || !datePickerRange.endDate ||
                (compared && (!compareDatePickerRange.startDate || !compareDatePickerRange.endDate)) ||
                (formatDate(datePickerRange.startDate) === compareDatePickerRange.startDate && formatDate(datePickerRange.endDate) === compareDatePickerRange.endDate)
              }
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal >
  );
};

export default DateRangeModal;
