
import React, { createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { LoadingButton, TabContext } from "@mui/lab";
import { Box, Grid, IconButton, MenuItem, Tabs, Typography, Tooltip, Menu, Alert, Snackbar, } from "@mui/material";
import { styled } from "@mui/material/styles";
import RGL, { WidthProvider } from "react-grid-layout";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { GetChannel } from "../../models/channel";
import { Report, REPORT_LEVEL, } from "../../models/report";
import {
    updateChart, updateGrid, updateTab, addReportData, updateReportData,
    getTabDetails, updateReportDateRange, updateReportAdAccCols
} from "../../api/report/action";
import { updateLoader } from "../../api/user/action";
import { changeCompareDate, changeDate, updateFilterChartMap } from "../../api/dashboard/action";
import { getAccountBasedColumns, getChartCurrency } from "../../api/channel/saga";
import { Errorhandler } from "../ErrorHanlder/ErrorHandler";
import ChartWrapper from "../Charts/ChartWrapper";
import DraggableTab from "./DraggableTab";
import Sidebar from "../EditWidget/Sidebar";
import FilterListModal from "../Filteration/FilterListModal";
import EditNameModal from "../Modal/EditNameModal";
import DeleteModal from "../Modal/DeleteModal";
import DateRangeModal from "../Modal/DateRangeModal";
import AccountModal from "../Modal/AccountModal";
import ShareModal from "../Modal/ShareModal";
import UnsavedModal from "../Modal/UnsavedModal";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";
import { ChartFilterSupport, DateDisplayFormatter } from "../Charts/ChartUtils";
import { reportingCalendarAPIWrapper, reportingCalendarUIWrapper } from "../../utils/calendarUtils";
import { ReactComponent as AddChartLogo } from "../../Assets/Images/logos_add_chart.svg";
import { ReactComponent as ArrowDrop } from "../../Assets/Icons/new_down_arrow.svg"
import { ReactComponent as RemoveChartLogo } from "../../Assets/Images/logos_remove_chart.svg";
import { ReactComponent as AddSourceIcon } from "../../Assets/Icons/add_source_icon.svg";
import { ReactComponent as UserIcon } from "../../Assets/Icons/user_plus.svg";
import { ReactComponent as AddTab } from "../../Assets/Icons/tab-icon.svg";
import { ReactComponent as FilterIcon } from "../../Assets/Icons/filterIcon.svg";
import { ReactComponent as CalenderNewIcon } from "../../Assets/Icons/calender-borderless.svg";
import { ReactComponent as EditIcon } from "../../Assets/Icons/edit-icon.svg";
import { ReactComponent as ExportIcon } from "../../Assets/Icons/export-icon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Icons/delete-icon.svg";
import { ReactComponent as AddFile } from "../../Assets/Icons/add-file-icon.svg";
import ThreeDots from "../../Assets/Images/logos_threedots.svg";
import CustomReportIcon from "../../Assets/ChannelIcons/custom_report.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SuccessModal from "../Modal/SuccessModal";
import FailureModal from "../Modal/FailureModal";

const ReactGridLayout = WidthProvider(RGL);
const drawerWidth = 355;
const SEPARATOR = ":";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        width: "100%",
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: 0,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: 0,
            }),
            marginRight: drawerWidth,
        }),
    })
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function ReportPage({
    tabId, setTabId, reportId, shared, report, setReport, callbackRedirection,
    unsavedModal, setUnsavedModal, setCallbackRedirection, navWidth = 0,
    allReports, reports, reportDownloaded, accountCols,
    filterChartMap, updateFilterChartMap, updateLoader,
    updateChart, updateGrid, updateTab,
    blends, addReportData, updateReportData,
    getTabDetails, updateReportDateRange, updateReportAdAccCols
}) {

    const history = useHistory();
    // const stateUpdateNumber = React.useRef(0);

    const userName = useSelector((state) => state.user.user?.name);
    const userRole = useSelector((state) => state.user.user?.role);
    const reportUpdates = useSelector(state => state.report.updates);

    const [filterListOpen, setFilterListOpen] = React.useState(false);
    const [updateNameModal, setUpdateNameModal] = React.useState({
        show: false,
        update: false,
    });

    const [isopen, setIsOpen] = useState(false)
    const [share, setIsShare] = useState(false)

    const [activeReport, setActiveReport] = useState(null);

    const selectedAccounts = (report?.adAccounts ?? []).reduce(
        (channels, account) => {
            channels[account.channelType ?? account.source] = (
                channels[account.channelType ?? account.source] ?? []
            ).concat(account);
            return channels;
        },
        {}
    );
    const [deleteModal, setDeleteModal] = React.useState({ open: false });
    const [editDrawerState, setEditDrawerState] = React.useState({
        isOpen: false,
        chart: null,
        grid: null,
        handleGridChange: (property, value, grid, tab_id) => {
            updateGrid({
                report_id: reportId,
                tab_id: tab_id,
                grid_id: grid.gridId,
                updates: [{
                    id: grid.id, grid_id: grid.gridId, tab_id: tab_id,
                    update_type: "update",
                    params: { [property]: value },
                }],
            });
        },
        handleChartChange: (property, value) => { },
    });
    const [open, setOpen] = React.useState(false);
    const [notifyOpen, setNotifyOpen] = React.useState(false);
    const [notifyMessage, setNotifyMessage] = React.useState("");

    const [refs, setRefs] = React.useState({});
    const [updateCount, setUpdateCount] = React.useState(1);
    const [gridChartCount, setGridChartCount] = React.useState([]);
    const [chartPagination, setChartPagination] = React.useState({
        start: 0,
        end: 8,
    });
    // const [isReportUpdated, setIsReportUpdated] = React.useState(false);
    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

    const [loading, setLoading] = React.useState(false);
    const [currentDateRange, setCurrentDateRange] = useState(null);
    const [currentComparedDateRange, setCurrentComparedDateRange] = useState(null);

    const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
    const [exportTabData, setExportTabData] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const [reportGeneration, setReportGeneration] = useState({ message: "none", error: "none", operation: "none" });

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
        setExportTabData(false);
        setDeleteModal({ open: false })
        setUnsavedModal?.({ open: false })
        setCallbackRedirection?.(null);
    };

    const openErrorMsg = () => {
        setOpen(true);
    };
    const closeErrorMsg = () => {
        setOpen(false);
    };

    const notifyBarOpen = () => {
        setNotifyOpen(true);
    };
    const notifyBarClose = () => {
        setNotifyOpen(false);
        setNotifyMessage("");
    };

    const viewDate = (date) => moment(date).format("Do MMM YYYY");

    const navigateTo = (reportId, reportName, tabId, tabName) => {
        if (shared) {
            setTabId(tabId);
        } else {
            const params = new URLSearchParams({ report_id: reportId, report_name: reportName, tab_id: tabId, tab_name: tabName });
            history.replace({ search: params.toString() });
        }
        setUpdateCount(updateCount + 1);
    };

    const handleAddNew = (title, type, icon) => {
        addReportData(Report.getPUTBodyPayload({ level: REPORT_LEVEL.TAB, type: "ADD", data: { position: Object.keys(report.tabs ?? {}).length, title }, reportId: report.report_id, _reportId: report.id }))
        setUpdateNameModal({ show: false, update: false });
    };

    const updateName = (title, type, icon) => {
        updateTab({
            report_id: reportId,
            tab_id: tabId,
            updates: [{ id: report.tabs[tabId].id, tab_id: tabId, update_type: "update", params: { title }, }],
        });
        setUpdateNameModal({ show: false, update: false });
        navigateTo(report.id, report.report_name, tabId, title);
    };

    const handleReportDelete = (report) => {
    };

    const handleTabCsvExport = (tabId) => {
        try {
            updateLoader(true);
            var date = [
                "Date Range: ",
                DateDisplayFormatter(currentDateRange?.startDate) +
                " - " +
                DateDisplayFormatter(currentDateRange?.endDate),
            ];
            let allData = [date, [], []];
            Object.keys(refs).forEach((chartId) => {
                const data = refs[chartId].current?.handleAllExport();
                if (data) {
                    allData = [...allData, ...data, [], []];
                }
            });
            const blob = new Blob([allData.join("\n")], {
                type: "text/csv",
            });
            const a = document.createElement("a");
            a.download = report.tabs[tabId].name
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .replaceAll(" ", "-")
                .toLowerCase();
            a.href = window.URL.createObjectURL(blob);
            a.dispatchEvent(
                new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                })
            );
            a.remove();
            updateLoader(false);
        }
        catch (err) {
            console.log("🚀 ~ handleTabCsvExport ~ err:", err)
            updateLoader(false);
        }
    };

    const padding = 15;
    let top = 15;

    const handleTabPdfExport = async (tabId) => {
        try {
            updateLoader(true);
            var date = `Date Range:  ${DateDisplayFormatter(
                currentDateRange.startDate
            )} - ${DateDisplayFormatter(currentDateRange.endDate)}`;
            const name = report.tabs[tabId].name
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .replaceAll(" ", "-")
                .toLowerCase();
            const doc = new jsPDF({});
            doc.text([date], padding, top);
            top += padding;
            for (let chartId of Object.keys(refs)) {
                const newTop = await refs[chartId].current?.handleAllPdfExport(doc, top);
                top = newTop ?? top;
            }
            doc.save(`${name}.pdf`);
            updateLoader(false);
        } catch (err) {
            console.log("🚀 ~ handleTabPdfExport ~ err:", err)
            updateLoader(false);
        }
    };

    const handleTabPngExport = () => {
        Object.keys(refs).forEach((chartId) => {
            refs[chartId].current?.handleAllPngExport();
        });
    };

    const handleTabJpgExport = () => {
        Object.keys(refs).forEach((chartId) => {
            refs[chartId].current?.handleAllJpgExport();
        });
    };

    const handleTabDelete = (tabId) => {
        if (Object.keys(report.tabs).length <= 1) {
            return;
        }
        const newRefs = refs;
        report.tabs[tabId].grids
            .reduce((prev, curr) => prev.concat(curr.charts), [])
            .forEach((chart) => {
                delete newRefs[chart.id];
            });
        setRefs({ ...newRefs });

        updateTab({
            report_id: reportId,
            tab_id: tabId,
            updates: [{ id: report.tabs[tabId].id, tab_id: tabId, update_type: "delete" }],
        });
        const newIndex = currentTabIndex === 0 ? 1 : 0;
        navigateTo(report.id, report.report_name, Object.values(report.tabs)[newIndex].tabId, Object.values(report.tabs)[newIndex].name);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;

        const source = result.source.index;
        const destination = result.destination.index;
        let updates;
        if (source > destination) {
            updates = Object.values(report.tabs)
                .filter((tab) => tab.position >= destination && tab.position <= source)
                .map((tab) => ({
                    id: tab.id,
                    tab_id: tab.tabId,
                    update_type: "update",
                    params: {
                        position: tab.position === source ? destination : tab.position + 1,
                    },
                }));
        } else {
            updates = Object.values(report.tabs)
                .filter((tab) => tab.position >= source && tab.position <= destination)
                .map((tab) => ({
                    id: tab.id,
                    tab_id: tab.tabId,
                    update_type: "update",
                    params: {
                        position: tab.position === source ? destination : tab.position - 1,
                    },
                }));
        }
        updateTab({ report_id: reportId, tab_id: tabId, updates });
    };

    const handleRemoveChart = (id, chartId, gridId) => {
        const newRefs = refs;
        delete newRefs[chartId];
        setRefs({ ...newRefs });
        updateChart({
            report_id: reportId,
            tab_id: tabId,
            grid_id: gridId,
            updates: [{ chart_id: chartId, id: id, update_type: "delete", }]
        });
    };

    const updateGridPos = (grid, event) => {
        const updates = [];
        event.forEach((e) => {
            const id = e.i.split(SEPARATOR)[0];
            const chart = grid?.charts.find((c) => c.chartId === id);
            if (
                chart &&
                (chart.gridPosition.x !== e.x ||
                    chart.gridPosition.y !== e.y ||
                    chart.gridPosition.w !== e.w ||
                    chart.gridPosition.h !== e.h)
            ) {
                // if (isReportUpdated) {
                updates.push({
                    chart_id: chart.chartId,
                    id: chart.id,
                    update_type: "update",
                    params: { grid_position: { ...e, minW: ["ADD", "KPI", "TEXT"].includes(chart.type) ? 3 : 6, minH: ["ADD", "KPI", "TEXT"].includes(chart.type) ? 1 : 2, maxH: 4 } },
                });
                // } else {
                //     chart.gridPosition.x = e.x;
                //     chart.gridPosition.y = e.y;
                //     chart.gridPosition.w = e.w;
                //     chart.gridPosition.h = e.h;
                //     chart.gridPosition.minW = ["ADD", "KPI", "TEXT"].includes(chart.type) ? 3 : 6;
                //     chart.gridPosition.minH = ["ADD", "KPI", "TEXT"].includes(chart.type) ? 1 : 2;
                //     chart.gridPosition.maxH = 4;
                // }
            }
        });
        if (updates.length > 0) {
            updateChart({
                report_id: reportId,
                tab_id: tabId,
                grid_id: grid.gridId,
                updates: updates,
            });
        }
    };

    const addNewBlock = (grid, _tabId) => {
        // const newRefs = {};
        const height = grid.charts.reduce(
            (max, chart) =>
                Math.max(max, chart.gridPosition.y + chart.gridPosition.h),
            0
        );
        addReportData(Report.getPUTBodyPayload({
            level: REPORT_LEVEL.CHART, type: "ADD",
            data: {
                y_position_chart: height
            }
            ,
            _tabId: _tabId, tabId: tabId,
            _gridId: grid.id, gridId: grid.gridId,
            reportId: report.report_id, _reportId: report.id
        }))
        // setRefs({ ...refs, ...newRefs });
        setChartPagination((prev) => ({ start: 0, end: prev.end + 4 }));
    };

    const removeAddBlock = (grid) => {
        const removedCharts = [];
        const chartsLength = grid.charts.length;
        for (let index = chartsLength - 1; index > (chartsLength > 4 ? chartsLength - 5 : 0); index--) {
            const chart = grid.charts[index];
            if (chart.type === "ADD") {
                removedCharts.push(grid.charts[index]);
            }
        }
        const newRefs = refs;
        updateChart({
            report_id: reportId,
            tab_id: tabId,
            grid_id: grid.gridId,
            updates: removedCharts.map((chart) => {
                delete newRefs[chart.id];
                return { chart_id: chart.chartId, id: chart.id, update_type: "delete" };
            }),
        });
        setRefs({ ...newRefs });
    };

    const checkGridRemove = (grid) => {
        const chartsLength = grid.charts.length;
        for (
            let index = chartsLength - 1;
            index > (chartsLength > 4 ? chartsLength - 5 : 0);
            index--
        ) {
            if (grid.charts[index].type === "ADD") {
                return true;
            }
        }
        return false;
    };

    const handleHeaderAdd = (_tabId) => {
        addReportData(Report.getPUTBodyPayload({
            level: REPORT_LEVEL.GRID, type: "ADD",
            data: {},
            _tabId: _tabId, tabId: tabId,
            reportId: report.report_id, _reportId: report.id
        }))
        setChartPagination((prev) => ({ start: 0, end: prev.end + 4 }));
    };

    const handleHeaderDelete = (grid) => {
        if (report.tabs[tabId].grids.length < 2) {
            openErrorMsg();
            return;
        }
        updateGrid({
            report_id: reportId,
            tab_id: tabId,
            grid_id: grid.gridId,
            updates: [{ id: grid.id, grid_id: grid.gridId, tab_id: tabId, update_type: "delete" }],
        });
    };

    const handleAllChartsForceReload = (filterId) => {
        filterChartMap[filterId]?.forEach((chartId) => {
            refs[chartId].current?.forceUpdateChart();
        });
    };

    const getActiveChartClass = (chartId) => {
        if (editDrawerState.isOpen && editDrawerState.chart?.id === chartId) {
            return "highlight-shadow";
        } else {
            return "";
        }
    };

    const applyDate = (datePickerRange) => {
        changeDate(datePickerRange);
    };

    function handleClick() {
        setLoading(true);
        updateReportData({
            reportId: report.report_id,
            updates: { last_updated_by: userName, UpdatedAt: new Date(), },
            stopLoading: () => setLoading(false)
        });
        callbackRedirection?.();
    }

    const onChangeDateRange = (uiDateRange) => {
        setCurrentDateRange(uiDateRange);
        // updateReportDateRange({report_id: report.report_id, date_frequency: reportingCalendarAPIWrapper([uiDateRange, currentComparedDateRange].filter(Boolean))});
    }

    const onChangeCompareDateRangeHandler = (uiCompareDateRange) => {
        setCurrentComparedDateRange(uiCompareDateRange);
        // updateReportDateRange({report_id: report.report_id, date_frequency: reportingCalendarAPIWrapper([currentDateRange, uiCompareDateRange])});
    }

    const onChangeCommonUpdateHandler = (uiDateRange, uiCompareDateRange) => {
        setCurrentDateRange(uiDateRange);
        setCurrentComparedDateRange(uiCompareDateRange);
        updateReportDateRange({ report_id: report.report_id, _report_id: report.id, date_frequency: reportingCalendarAPIWrapper([uiDateRange, uiCompareDateRange].filter(Boolean)) });
    }

    function handleScroll() {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;
        const scrollBottom = scrollTop + windowHeight;

        if (scrollBottom >= documentHeight - 5) {
            setChartPagination((prev) => ({ start: 0, end: prev.end + 8 }));
        }
    }

    const getAccountCols = (account) => {
        const channel = GetChannel(account.channelType ?? account.source)
        let accountCurrency = null;
        getChartCurrency(account, channel)
            .then((data) => {
                accountCurrency = data.currency ?? null;
            }).finally(() => {
                getAccountBasedColumns(account, channel)
                    .then((data) => {
                        let columns = data?.columns ?? [];
                        updateReportAdAccCols({
                            [account.title]: {
                                metrics: columns.filter((c) => c.type === "METRIC"),
                                dimensions: columns.filter((c) => c.type === "DIMENSION"),
                                currency: accountCurrency
                            }
                        })
                    })
                    .catch((err) => {
                        console.log("🚀 ~ getAccountBasedColumns ~ err:", err, account);
                        updateReportAdAccCols({ [account.title]: { metrics: [], dimensions: [], currency: null } })
                    })
                    .finally(() => {
                        setLoading((prev) => prev - 1);
                    });
            });
    };

    useEffect(() => {
        if (!isopen) {
            setActiveReport(null)
        }
    }, [isopen])

    useEffect(() => {
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 0);
    }, [editDrawerState.isOpen]);

    // useEffect(() => {
    //     stateUpdateNumber.current++;
    //     if (stateUpdateNumber.current >= 2 && reportDownloaded) {
    //         // first to get initial redux state, then state update when API call is made, then state update when API call completed
    //         setIsReportUpdated(true);
    //         // if (showTutorial && run === false) {
    //         //     setStartTour(true);
    //         // }
    //     }
    // }, [reportDownloaded]);

    useEffect(() => {
        setChartPagination({ start: 0, end: 8 });
        setExportTabData(false);
    }, [updateCount]);

    useEffect(() => {
        setGridChartCount([]);
        let childRefs = {};
        let filter_chart_map = {};
        report.tabs?.[tabId]?.grids?.forEach((grid, gridIndex) => {
            let maxY = 0;
            grid.charts.forEach((chart, chartIndex) => {
                const { gridPosition: { y, h } = {} } = chart;
                maxY = maxY > y + h ? maxY : y + h;
                childRefs[chart.id] = createRef();
                if (typeof chart.channelType === "object" && chart.channelType?.id) {
                    const blendId = chart.channelType.id;
                    const blendSource = blends.find(
                        (source) => source.id === chart.channelType.id
                    );
                    blendSource?.dsConfigs.forEach((source) => {
                        const filterId = source.dsOptions?.filterGrouping?.filterId;
                        filter_chart_map[filterId] = (
                            filter_chart_map[filterId] ?? []
                        ).concat(chart.id);
                    });
                } else {
                    if (ChartFilterSupport(chart.type)) {
                        const filterId = chart.filter?.id;
                        filter_chart_map[filterId] = (
                            filter_chart_map[filterId] ?? []
                        ).concat(chart.id);
                    } else {
                        const combinedMetrics = chart.leftMetrics.concat(
                            chart.rightMetrics ?? []
                        );
                        for (let metric of combinedMetrics) {
                            const filterId = metric?.filter?.id;
                            if (
                                filterId &&
                                !(filter_chart_map[filterId] ?? []).includes(chart.id)
                            ) {
                                filter_chart_map[filterId] = (
                                    filter_chart_map[filterId] ?? []
                                ).concat(chart.id);
                            }
                        }
                    }
                }
            });
            setGridChartCount((prev) =>
                prev.concat(gridIndex ? prev[gridIndex - 1] + maxY : maxY)
            );
        });
        setRefs({ ...childRefs });
        updateFilterChartMap(filter_chart_map);
    }, [updateCount, report.tabs?.[tabId]?.grids]);

    useEffect(() => {
        if (reportId && allReports?.length) {
            const foundReport = allReports?.find(report => report.id === +reportId);
            const dateRanges = reportingCalendarUIWrapper(foundReport?.date_frequency);
            setCurrentDateRange(dateRanges?.[0])
            setCurrentComparedDateRange(dateRanges?.[1]);
        }
    }, [reportId, allReports]);

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        const accounts = report.adAccounts ?? [];
        let accLen = accounts.length;
        if (accLen) {
            if (firstLoad || accLen - 1 > Object.keys(accountCols).length) {
                setFirstLoad(false);
                setLoading((prev) => prev + accounts.length);
                for (let account of accounts) {
                    if (!accountCols[account.title]) {
                        getAccountCols(account);
                    } else {
                        setLoading((prev) => prev - 1);
                    }
                }
            } else {
                let lastAccount = accounts[accLen - 1];
                if (!accountCols[lastAccount.title]) {
                    setLoading((prev) => prev + 1);
                    getAccountCols(lastAccount);
                }
            }
        }
    }, [reportId]);

    useEffect(() => {
        if ((tabId && reports[reportId]) && !(reports[reportId][tabId] && reports[reportId][tabId].grids)) {
            getTabDetails({
                reportId,
                tabId
            })
        }

        if (tabId && reports[reportId] && reports[reportId][tabId]) {
            setCurrentTabIndex(reports[reportId][tabId].position);
        }
    }, [tabId, reports[reportId]]);

    useEffect(() => {
        handleMixPanelData(EVENTS.view_report_report, report);
        // Add Scroll event listener to load more charts
        window.addEventListener("scroll", handleScroll);
        // Clean up the event listener
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const reportCallback = ({ message, error, operation = "Creation" }) => {
        setIsShare(false);
        setReportGeneration({ message, error, operation });
    }

    return (
        <>
            <Main open={editDrawerState.isOpen}>
                {/* <DrawerHeader /> */}
                <div style={{ padding: `24px 24px 0px ${navWidth + 24}px`, width: "100%", zIndex: 1000, background: "#f6f7fb", position: "fixed" }}>
                    <div className="top_bar">
                        <div className="connect_data">
                            <p style={{ height: "40px", width: "40px", background: "#F6F7F8", margin: "0px", borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img
                                    style={{
                                        height: "24px",
                                        width: "24px",
                                    }}
                                    src={
                                        report.icon ?? report.report_icon ?? CustomReportIcon
                                    }
                                    alt={report.report_name}
                                />
                            </p>
                            <div className="edit_toggle">
                                <Typography
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        lineHeight: "24px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {report.report_name}
                                    {!shared && <ArrowDrop
                                        style={{
                                            marginLeft: "10px",
                                            cursor: 'pointer'
                                        }}
                                        onClick={(e) => setAnchorEl2(e.currentTarget)}
                                    />}
                                </Typography>
                                <Menu id="menu-appbar" className="smooth-shadow"
                                    anchorEl={anchorEl2}
                                    keepMounted
                                    getContentAnchorEl={null}
                                    variant='menu'
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center", }}
                                    transformOrigin={{ vertical: "top", horizontal: "center", }}
                                    open={Boolean(anchorEl2)}
                                    onClose={handleClose}
                                    sx={{
                                        "& .MuiMenu-paper": {
                                            borderRadius: "8px",
                                            padding: "8px",
                                            minWidth: "168px",
                                            gap: "12px",
                                        },
                                        "& .MuiMenuItem-root": {
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "20px",
                                            backgroundColor: "transparent"
                                        },
                                        "&.Mui-selected": {
                                            backgroundColor: "transparent"
                                        }
                                    }}
                                >

                                    {/* <MenuItem
                                        onClick={() => {
                                            setActiveReport(report);
                                            setIsOpen(true);
                                            handleClose();
                                            handleMixPanelData(EVENTS.add_sources_reports, { report_name: report?.report_name });
                                        }}
                                    >
                                        <AddSourceIcon
                                            style={{
                                                marginRight: "12px",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                        />
                                        {""}
                                        Add Sources
                                    </MenuItem> */}

                                    <MenuItem
                                        onClick={() => {
                                            setIsShare(true)
                                            handleClose()
                                            setActiveReport(report);
                                        }}
                                    >
                                        <UserIcon
                                            style={{
                                                marginRight: "12px",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                        />{" "}
                                        Share
                                    </MenuItem>
                                </Menu>
                                <Tooltip
                                    PopperProps={{
                                        sx: {
                                            "& .MuiTooltip-arrow": {
                                                color: "white",
                                            },
                                            "& .MuiTooltip-tooltip": {
                                                backgroundColor: "white",
                                                color: "black",
                                                marginBottom: "20px",
                                                filter:
                                                    "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                                                padding: "20px 25px",
                                            },
                                        },
                                    }}
                                    title={
                                        Object.keys(selectedAccounts).length ? (
                                            <Box
                                                sx={{
                                                    borderRadius: "12px",
                                                    width: "277px",
                                                    height: "auto",
                                                    maxHeight: "56vh",
                                                    overflow: "auto",
                                                }}
                                            >
                                                {Object.keys(selectedAccounts).map(
                                                    (channelType, index) => {
                                                        const channel = GetChannel(channelType) ?? {};
                                                        return (
                                                            <div
                                                                key={channel.type}
                                                                value={channel.name}
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginTop:
                                                                            index === 0 ? "0px" : "10px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "10px",
                                                                            marginBottom: "10px",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                background: "#F6F7F8",
                                                                                borderRadius: "4px",
                                                                                height: "32px",
                                                                                width: "32px",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                style={{
                                                                                    height: 18,
                                                                                    width: 18,
                                                                                }}
                                                                                src={channel.icon}
                                                                                alt={channel.title}
                                                                            />
                                                                        </div>
                                                                        <Typography
                                                                            style={{
                                                                                fontFamily: "Inter",
                                                                                fontSize: "14px",
                                                                                fontWeight: "700",
                                                                            }}
                                                                        >
                                                                            {channel.title} ( {selectedAccounts[channelType].length} )
                                                                        </Typography>
                                                                    </div>
                                                                    <ul style={{ paddingLeft: "20px" }}>
                                                                        {selectedAccounts[
                                                                            channelType
                                                                        ].map((acc) => (
                                                                            <>
                                                                                <li
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        fontFamily: "Inter",
                                                                                        marginBottom: "5px",
                                                                                    }}
                                                                                >
                                                                                    {acc.title}
                                                                                    <Typography
                                                                                        style={{
                                                                                            fontSize: "10px",
                                                                                            fontFamily: "Inter",
                                                                                            color: "#585969",
                                                                                        }}
                                                                                    >
                                                                                        {acc.subtitle}
                                                                                    </Typography>
                                                                                </li>
                                                                            </>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                {Object.keys(selectedAccounts).length !== index + 1 && (
                                                                    <p style={{ borderBottom: "1px solid #ECEEF1", width: "90%", marginTop: "10px", }} ></p>
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </Box>
                                        ) : ("")
                                    }
                                    arrow
                                    placement="bottom"
                                >
                                    <div className="connected_accounts" style={{ display: 'flex', flexDirection: "row", width: '110px', overflowY: 'scroll', gap: '6px' }}>

                                        {Object.keys(selectedAccounts).map((channelType, index) => {
                                            const channel = GetChannel(channelType);
                                            return (
                                                <img
                                                    src={channel?.icon}
                                                    alt={channel?.title}
                                                    height={16}
                                                    width={16}
                                                />
                                            )
                                        })}
                                    </div>
                                </Tooltip>

                            </div>

                        </div>
                        {!shared && < div style={{ display: "flex", alignItems: "end", flexDirection: "column-reverse", gap: "5px", justifyContent: "flex-end" }}>
                            <Typography style={{ fontFamily: "Inter !important", fontSize: "10px", fontWeight: "400", color: "#5C6674" }}>
                                {report.createdAt === report.last_updated_at ? "Created " : "Last saved "} by {report.last_updated_by} at {moment(report.last_updated_at).format('DD MMM h:mm A')}
                            </Typography>

                            <LoadingButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                    handleClick();
                                    setReport(Report.fromJSON({ ...(report.toJSON() || {}), last_updated_by: userName, UpdatedAt: new Date(), }));
                                    handleMixPanelData(EVENTS.click_save_report, report);
                                }}
                                disabled={!reportUpdates?.length}
                                loading={!!loading}
                                loadingPosition="end"
                                variant="outlined"
                                sx={{
                                    "& .MuiLoadingButton-loadingIndicator": {
                                        position: "relative",
                                        right: "0px"
                                    },
                                    display: "flex",
                                    gap: "10px",
                                    minWidth: "100px"
                                }}
                            >
                                <span>Save</span>
                            </LoadingButton>
                        </div>}

                    </div>
                </div>
                <div className="dashboard" style={{ margin: `112px 24px 24px ${navWidth + 24}px` }}>

                    <Grid
                        container
                        rowSpacing={0}
                        spacing={0}
                        justify="space-around"
                        alignItems="stretch"
                        style={{
                            padding: "0px 14px",
                            margin: "0 auto",
                            // maxWidth: "1700px",
                            borderRadius: "12px",
                            boxShadow: "0px 12px 40px -16px rgba(0, 0, 0, 0.05)",
                            background: "#FFFFFF",
                        }}
                    >

                        <Grid rowSpacing={0} item xs={12} align="center">
                            <Box sx={{ width: "100%" }} className="dashboard-tabs">
                                <TabContext value={currentTabIndex}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "0px 10px",
                                            paddingBottom: "0",
                                            marginTop: "24px"
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                maxWidth: "calc(100% - 300px)",
                                            }}
                                        >
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="1" direction="horizontal">
                                                    {(droppableProvided) => (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "calc(100% - 35px)",
                                                            }}
                                                            ref={droppableProvided.innerRef}
                                                            {...droppableProvided.droppableProps}
                                                        >
                                                            <Tabs
                                                                value={currentTabIndex}
                                                                onChange={(event, newValue) => {
                                                                    const tabsArray = Object.values(report?.tabs ?? {});
                                                                    const clickedTab = tabsArray.find(tab => tab.position === newValue);
                                                                    navigateTo(
                                                                        report.id,
                                                                        report.report_name,
                                                                        clickedTab.tabId,
                                                                        clickedTab.name,
                                                                    )
                                                                }}
                                                                TabIndicatorProps={{ style: { backgroundColor: "#0869FB", width: "21%" } }}
                                                                variant="scrollable"
                                                                scrollButtons={false}
                                                                aria-label="scrollable auto tabs example"
                                                                style={{ maxWidth: "50vw" }}
                                                                selectionFollowsFocus
                                                            >
                                                                {Object.values(report?.tabs ?? {}).sort((tab1, tab2) => tab1.position - tab2.position).map((tab, tabIndex) => {
                                                                    return (
                                                                        <DraggableTab
                                                                            label={
                                                                                <div style={{
                                                                                    whiteSpace: "nowrap", overflow: "hidden",
                                                                                    textOverflow: "ellipsis", minWidth: "120px"

                                                                                }}>
                                                                                    {tab.name}
                                                                                </div>
                                                                            }
                                                                            value={tab.value}
                                                                            tab={tab}
                                                                            tabIndex={tabIndex}
                                                                            key={tabIndex}
                                                                            currentTabIndex={currentTabIndex}
                                                                            reportId={reportId}
                                                                            updateTab={updateTab}
                                                                            setAnchorEl={setAnchorEl}
                                                                        />
                                                                    );
                                                                })}
                                                                {droppableProvided
                                                                    ? droppableProvided.placeholder
                                                                    : null}
                                                            </Tabs>
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            {!shared && <Tooltip title={"Add New Tab"} placement="bottom">
                                                <IconButton
                                                    data-tut="add_tab"
                                                    style={{
                                                        padding: "0px",
                                                        height: "32px",
                                                        width: "32px",
                                                        marginLeft: "20px",
                                                    }}
                                                    onClick={() => {
                                                        setUpdateNameModal({
                                                            show: "Tab",
                                                            update: false,
                                                        })
                                                        handleMixPanelData(EVENTS.add_tab_report, { report_name: report?.report_name });
                                                    }}
                                                >
                                                    <AddTab />
                                                </IconButton>
                                            </Tooltip>}
                                            <Menu
                                                id="menu-appbar"
                                                className="smooth-shadow"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                getContentAnchorEl={null}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                                sx={{
                                                    marginTop: 3.5,
                                                    "& 		.MuiMenu-paper": {
                                                        borderRadius: "8px",
                                                        padding: "8px",
                                                        minWidth: "168px",
                                                        gap: "12px",
                                                    },
                                                    "& .MuiMenuItem-root": {
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "20px",
                                                        backgroundColor: "transparent"
                                                    },
                                                }}
                                            >
                                                {!exportTabData ? (
                                                    <>
                                                        {/* <MenuItem onClick={() => { setUpdateNameModal({ show: "Tab", update: true }); handleClose(); }}>Rename Tab</MenuItem> */}
                                                        {!shared && <MenuItem
                                                            onClick={() => {
                                                                setUpdateNameModal({
                                                                    show: "Tab",
                                                                    update: true,
                                                                });
                                                                handleClose();
                                                            }}
                                                        >
                                                            {" "}
                                                            <EditIcon
                                                                style={{
                                                                    marginRight: "12px",
                                                                    width: "20px",
                                                                    height: "20px",
                                                                }}
                                                            />{" "}
                                                            Edit{" "}
                                                        </MenuItem>}

                                                        <MenuItem
                                                            onClick={() => setExportTabData(true)}
                                                        >
                                                            {" "}
                                                            <ExportIcon
                                                                style={{
                                                                    marginRight: "12px",
                                                                    width: "20px",
                                                                    height: "12px",
                                                                }}
                                                            />{" "}
                                                            Export{" "}
                                                        </MenuItem>
                                                        {/* <MenuItem onClick={() => { setUpdateNameModal({ show: "Tab", update: false }); handleClose(); }}>Add New Tab </MenuItem> */}
                                                        {!shared && <MenuItem
                                                            onClick={() => {
                                                                handleClose();
                                                                handleTabDelete(tabId);
                                                            }}
                                                        >
                                                            <DeleteIcon
                                                                style={{
                                                                    marginRight: "12px",
                                                                    width: "20px",
                                                                    height: "20px",
                                                                }}
                                                            />{" "}
                                                            Delete{" "}
                                                        </MenuItem>}
                                                    </>
                                                ) : (
                                                    <>
                                                        <MenuItem
                                                            style={{
                                                                paddingLeft: "10px",
                                                                color: "#00000099",
                                                                fontSize: "14px",
                                                            }}
                                                            onClick={() => setExportTabData(false)}
                                                        >
                                                            <ArrowBackIcon style={{ width: "16px" }} />
                                                            <p
                                                                style={{
                                                                    margin: "auto 5px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {" "}
                                                                Export
                                                            </p>{" "}
                                                        </MenuItem>
                                                        {/* <MenuItem
                                                            style={{ paddingLeft: "30px" }}
                                                            onClick={() => {
                                                                handleClose();
                                                                handleTabPdfExport(tabId);
                                                            }}
                                                        >
                                                            PDF Format
                                                        </MenuItem> */}
                                                        <MenuItem
                                                            style={{ paddingLeft: "30px" }}
                                                            onClick={() => {
                                                                handleClose();
                                                                handleTabPngExport(tabId);
                                                            }}
                                                        >
                                                            PNG Format
                                                        </MenuItem>
                                                        <MenuItem
                                                            style={{ paddingLeft: "30px" }}
                                                            onClick={() => {
                                                                handleClose();
                                                                handleTabJpgExport(tabId);
                                                            }}
                                                        >
                                                            JPG Format
                                                        </MenuItem>
                                                        <MenuItem
                                                            style={{ paddingLeft: "30px" }}
                                                            onClick={() => {
                                                                handleClose();
                                                                handleTabCsvExport(tabId);
                                                            }}
                                                        >
                                                            CSV Format
                                                        </MenuItem>
                                                    </>
                                                )}
                                            </Menu>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "row" }}>

                                            <div
                                                onClick={() => {
                                                    setIsDatePickerOpen(true);
                                                    handleMixPanelData(EVENTS.click_calendar_report, { report_name: report.report_name });
                                                }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    border: "1px solid #EAEAEC",
                                                    borderRadius: "6px",
                                                    minWidth: "200px",
                                                    minHeight: "40px",
                                                    maxHeight: "40px",
                                                    padding: "12px 16px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <IconButton
                                                    style={{
                                                        width: "16px",
                                                        height: "16px",
                                                        marginRight: "10px",
                                                        padding: "0px",
                                                    }}
                                                    onClick={() => setIsDatePickerOpen(true)}
                                                >
                                                    <CalenderNewIcon />
                                                </IconButton>
                                                <Typography
                                                    component="div"
                                                    sx={{ flexGrow: 1 }}
                                                    style={{
                                                        lineHeight: "16px",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {/* <span
                                style={{
                                  color: "#AEB6C4",
                                  fontFamily: "Inter",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  lineHeight: "8px",
                                }}
                              >
                                {dateRange.label ?? "Custom date range"}
                              </span>
                              <br /> */}
                                                    <span
                                                        style={{
                                                            fontFamily: "Inter",
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            fontStyle: "normal",
                                                            letterSpacing: "0.12px",
                                                            lineHeight: "8px",
                                                            color: "#585969",
                                                        }}
                                                    >
                                                        {viewDate(currentDateRange?.startDate)} -{" "}
                                                        {viewDate(currentDateRange?.endDate)}
                                                    </span>
                                                    {currentComparedDateRange?.label && <>
                                                        <br /><span
                                                            style={{
                                                                fontFamily: "Inter",
                                                                fontSize: "10px",
                                                                fontWeight: "400",
                                                                fontStyle: "normal",
                                                                letterSpacing: "0.12px",
                                                                lineHeight: "8px",
                                                                color: "#AEB6C4",
                                                            }}
                                                        >
                                                            {"vs " + viewDate(currentComparedDateRange?.startDate)} -{" "}
                                                            {viewDate(currentComparedDateRange?.endDate)}
                                                        </span>
                                                    </>}
                                                </Typography>
                                                {/* <IconButton
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "10px",
                                padding: "0px",
                              }}
                              onClick={() => setIsDatePickerOpen(true)}
                            >
                              <ArrowDropDownIcon />
                            </IconButton> */}
                                            </div>
                                            {!shared && <div
                                                style={{
                                                    height: "40px", minWidth: "40px", display: "flex", border: "1px solid #ECEEF1", borderRadius: "4px", alignItems: "center", cursor: "pointer", padding: "0px 10px", marginLeft: "10px",
                                                }}
                                                onClick={() => setFilterListOpen(true)}
                                            >
                                                <FilterIcon
                                                    data-tut="filter_box"
                                                    style={{ height: "18px", width: "18px" }}
                                                    onClick={() => setFilterListOpen(true)}
                                                />
                                            </div>}
                                        </div>
                                    </Box>
                                    {Object.values(report?.tabs ?? {}).sort((tab1, tab2) => tab1.position - tab2.position).map((tab, tabIndex) => (
                                        <TabPanel
                                            key={tab.id}
                                            value={currentTabIndex}
                                            index={tabIndex}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                                rowSpacing={0}
                                                justify="space-evenly"
                                                alignItems="center"
                                            >
                                                {(tab?.grids ?? [])
                                                    .filter((grid, index) =>
                                                        index
                                                            ? gridChartCount[index - 1] <=
                                                            chartPagination.end
                                                            : true
                                                    )
                                                    .map((grid, gridIndex) => (
                                                        <Grid key={grid.id} item xs={12}>
                                                            {grid.title && (
                                                                <div
                                                                    className="tab-section-header"
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "space-between",
                                                                        position: "relative",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            alignItems: "flex-start",
                                                                        }}
                                                                    >
                                                                        <Typography variant="h6"
                                                                            style={{
                                                                                // lineHeight: "26px",
                                                                                fontFamily: grid.gridStyle.titleStyle.font,
                                                                                fontSize: grid.gridStyle.titleStyle.fontSize,
                                                                                fontWeight: grid.gridStyle.titleStyle.fontFormat.includes("bold") && "bold",
                                                                                fontStyle: grid.gridStyle.titleStyle.fontFormat.includes("italic") && "italic",
                                                                                textDecorationLine: grid.gridStyle.titleStyle.fontFormat.includes("underline") && "underline",
                                                                                lineHeight: (8 + Number(grid.gridStyle.titleStyle.fontSize)) + "px",
                                                                                color: grid.gridStyle.titleStyle.color,
                                                                            }}
                                                                        >
                                                                            {grid.title}
                                                                        </Typography>
                                                                        <Typography variant="caption"
                                                                            style={{
                                                                                // lineHeight: "20px",
                                                                                fontFamily: grid.gridStyle.subTitleStyle.font,
                                                                                fontSize: grid.gridStyle.subTitleStyle.fontSize,
                                                                                fontWeight: grid.gridStyle.subTitleStyle.fontFormat.includes("bold") && "bold",
                                                                                fontStyle: grid.gridStyle.subTitleStyle.fontFormat.includes("italic") && "italic",
                                                                                textDecorationLine: grid.gridStyle.subTitleStyle.fontFormat.includes("underline") && "underline",
                                                                                lineHeight: (8 + Number(grid.gridStyle.subTitleStyle.fontSize)) + "px",
                                                                                color: grid.gridStyle.subTitleStyle.color,
                                                                            }}
                                                                        >
                                                                            {grid.subtitle}
                                                                        </Typography>
                                                                    </div>

                                                                    {!shared && <div
                                                                        onClick={(e) =>
                                                                            setAnchorEl3({
                                                                                target: e.currentTarget,
                                                                                grid: grid,
                                                                            })
                                                                        }
                                                                        style={{
                                                                            display: "flex",
                                                                            height: "30px",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            border: "1px solid #eaeaec",
                                                                            width: " 30px",
                                                                            borderRadius: 4,
                                                                            cursor: "pointer",
                                                                            // position: "absolute",
                                                                            // right: "11px",
                                                                        }}
                                                                    >
                                                                        <IconButton disableRipple style={{ height: "32px", width: "32px", }} >
                                                                            <img src={ThreeDots} alt="dots" />
                                                                        </IconButton>
                                                                    </div>}

                                                                    {!shared && <Menu
                                                                        id="menu-appbar"
                                                                        className="smooth-shadow"
                                                                        anchorEl={anchorEl3?.target}
                                                                        keepMounted
                                                                        getContentAnchorEl={null}
                                                                        anchorOrigin={{
                                                                            vertical: "bottom",
                                                                            horizontal: "right",
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "right",
                                                                        }}
                                                                        open={Boolean(anchorEl3?.target)}
                                                                        onClose={handleClose}
                                                                        sx={{
                                                                            marginTop: "10px",
                                                                            "& 		.MuiMenu-paper": {
                                                                                borderRadius: "8px",
                                                                                padding: "8px",
                                                                                minWidth: "168px",
                                                                                gap: "12px",
                                                                            },
                                                                            "& .MuiMenuItem-root": {
                                                                                fontFamily: "Inter",
                                                                                fontSize: "14px",
                                                                                fontWeight: "400",
                                                                                lineHeight: "20px",
                                                                            },
                                                                        }}
                                                                    >
                                                                        <MenuItem
                                                                            onClick={() => {
                                                                                setEditDrawerState({
                                                                                    ...editDrawerState,
                                                                                    isOpen: true,
                                                                                    grid: anchorEl3.grid,
                                                                                    tab: tabId,
                                                                                    chart: null,
                                                                                });
                                                                                handleClose();
                                                                            }}
                                                                        >
                                                                            <EditIcon
                                                                                style={{
                                                                                    marginRight: "12px",
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                }}
                                                                            />
                                                                            {""}
                                                                            Edit Header
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            onClick={() => {
                                                                                handleHeaderAdd(tab.id);
                                                                                handleClose();
                                                                            }}
                                                                        >
                                                                            <AddFile
                                                                                style={{
                                                                                    marginRight: "12px",
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                }}
                                                                            />
                                                                            Add New Header
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            onClick={() => {
                                                                                setDeleteModal({ open: true, type: "Header", name: anchorEl3?.grid?.title });
                                                                            }}
                                                                        >
                                                                            <DeleteIcon
                                                                                style={{
                                                                                    marginRight: "12px",
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                }}
                                                                            />{" "}
                                                                            Delete Header
                                                                        </MenuItem>
                                                                    </Menu>}
                                                                </div>
                                                            )}

                                                            <Grid item xs={12}>
                                                                <ReactGridLayout
                                                                    isBounded={true} rowHeight={200} cols={12} padding={0}
                                                                    resizeHandles={["ne", "nw", "se", "sw", "e", "n", "s", "w"]}
                                                                    draggableHandle=".drag-handle"
                                                                    layout={grid.charts.map((chart, idx) => chart?.gridPosition)}
                                                                    onLayoutChange={(event) => { updateGridPos(grid, event); }}
                                                                >
                                                                    {grid.charts
                                                                        .filter((chart, index) => {
                                                                            let maxY = chart?.gridPosition?.y + chart?.gridPosition?.h;
                                                                            return !index
                                                                                ? true
                                                                                : gridIndex
                                                                                    ? (gridChartCount[gridIndex - 1] + maxY) <= chartPagination.end
                                                                                    : maxY <= chartPagination.end;
                                                                        })
                                                                        .map((chart) => (
                                                                            <div
                                                                                className={`flexible-box same-padding ${getActiveChartClass(chart.id)}`}
                                                                                key={chart.chartId}
                                                                                data-grid={chart.gridPosition}
                                                                            >
                                                                                <Errorhandler
                                                                                    errorMessage="Error Loading Chart"
                                                                                    removeChart={() => {
                                                                                        handleRemoveChart(chart.id, chart.chartId, grid.gridId);
                                                                                    }}
                                                                                >
                                                                                    <ChartWrapper
                                                                                        ref={refs[chart?.id]}
                                                                                        chart={chart}
                                                                                        report={report?.report_name}
                                                                                        reportAdAccs={report?.adAccounts}
                                                                                        dateFrequency={report?.date_frequency}
                                                                                        gridId={grid.gridId}
                                                                                        removeChart={() => {
                                                                                            handleRemoveChart(chart.id, chart.chartId, grid.gridId);
                                                                                        }}
                                                                                        handleToggleEditDrawer={(
                                                                                            chart,
                                                                                            handleChartChange
                                                                                        ) =>
                                                                                            setEditDrawerState({
                                                                                                ...editDrawerState,
                                                                                                isOpen: true,
                                                                                                chart: chart,
                                                                                                grid: null,
                                                                                                handleChartChange: handleChartChange,
                                                                                            })
                                                                                        }
                                                                                        closeEditDrawer={(chart) => {
                                                                                            if (
                                                                                                chart?.id ===
                                                                                                editDrawerState?.chart?.id
                                                                                            )
                                                                                                setEditDrawerState({
                                                                                                    ...editDrawerState,
                                                                                                    isOpen: false,
                                                                                                });
                                                                                        }}
                                                                                        shared={shared}
                                                                                    />
                                                                                </Errorhandler>
                                                                            </div>
                                                                        ))}
                                                                </ReactGridLayout>
                                                                {(chartPagination.end < gridChartCount[gridIndex] || shared) ? <></>
                                                                    : (<>
                                                                        {checkGridRemove(grid) && (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    marginLeft: "-20px",
                                                                                    marginRight: "10px",
                                                                                }}
                                                                            >
                                                                                <RemoveChartLogo
                                                                                    style={{
                                                                                        width: "30px",
                                                                                        height: "30px",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        removeAddBlock(grid)
                                                                                    }
                                                                                />
                                                                                <div
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: "8px",
                                                                                        background: "#D9D9D999",
                                                                                    }}
                                                                                ></div>
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginLeft: "-20px",
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            <AddChartLogo
                                                                                style={{
                                                                                    width: "30px",
                                                                                    height: "30px",
                                                                                }}
                                                                                onClick={() => addNewBlock(grid, tab.id)}
                                                                            />
                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height: "8px",
                                                                                    background: "#D9D9D999",
                                                                                }}
                                                                            ></div>
                                                                        </div>
                                                                    </>)}
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                            </Grid>
                                        </TabPanel>
                                    ))}
                                </TabContext>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </Main >
            {isDatePickerOpen &&
                <DateRangeModal
                    dateRange={currentDateRange}
                    compareDateRange={currentComparedDateRange}
                    changeCompareDate={onChangeCompareDateRangeHandler}
                    setDateRange={onChangeDateRange}
                    isDatePickerOpen={isDatePickerOpen}
                    setIsDatePickerOpen={setIsDatePickerOpen}
                    other={{ report_name: report.report_name }}
                    commonUpdateHandler={onChangeCommonUpdateHandler}
                    shared={shared}
                />
            }
            <EditNameModal
                key={updateNameModal.show}
                oldName={
                    updateNameModal.update
                        ? updateNameModal.show === "Report"
                            ? report.report_name
                            : report.tabs[tabId]?.name
                        : null
                }
                oldIcon={
                    updateNameModal.update ? report.report_icon : CustomReportIcon
                }
                modal={updateNameModal}
                setModal={setUpdateNameModal}
                update={updateNameModal.update ? updateName : handleAddNew}
                allNames={
                    updateNameModal.show === "Report"
                        ? allReports?.map((report) => report.report_name)
                        : Object.values(report?.tabs ?? {})?.map((tab) => tab.name)
                }
            />
            <Sidebar
                shared={shared}
                drawerWidth={drawerWidth}
                editDrawerState={editDrawerState}
                reportAdAccs={report?.adAccounts}
                onClose={() =>
                    setEditDrawerState({ ...editDrawerState, isOpen: false })
                }
                handleAllChartsForceReload={handleAllChartsForceReload}
                report={report?.report_name}
            />
            {
                report?.adAccounts && !shared &&
                <FilterListModal
                    report={report}
                    modal={filterListOpen}
                    setModal={setFilterListOpen}
                    handleAllChartsForceReload={handleAllChartsForceReload}
                />
            }
            <Snackbar open={open} autoHideDuration={3000} onClose={closeErrorMsg}>
                <Alert
                    onClose={closeErrorMsg}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    Cannot delete header. Must have at least one header in a tab.
                </Alert>
            </Snackbar>
            <Snackbar
                open={notifyOpen}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={5000}
                onClose={notifyBarClose}
            >
                <Alert
                    onClose={notifyBarClose}
                    severity={notifyMessage.indexOf("wrong") > -1 ? "error" : "success"}
                    sx={{ width: "100%" }}
                >
                    {notifyMessage}
                </Alert>
            </Snackbar>
            <DeleteModal
                modal={deleteModal.open}
                setModal={handleClose}
                message={`Do you really want to delete this ${deleteModal.type} - ${deleteModal.name}? The access will be revoked from all invited users.`}
                onDelete={() => {
                    if (deleteModal.type === "Report") {
                        handleReportDelete(report);
                    } else {
                        handleHeaderDelete(anchorEl3.grid);
                        handleClose();
                    }
                }}
            />
            {unsavedModal && <UnsavedModal
                modal={unsavedModal.open}
                setModal={handleClose}
                message={`There are unsaved changes in the report. Do you want to save these changes before navigating away?`}
                onChange={() => {
                    handleClick();
                }}
                callbackRedirection={callbackRedirection}
            />}
            {isopen && <AccountModal isopen={isopen} setIsOpen={setIsOpen} report={activeReport} />}
            {share && <ShareModal isopen={share} setIsOpen={setIsShare} report={activeReport} reportCallback={reportCallback} />}
            <SuccessModal
                modal={reportGeneration.message === "Success"}
                setModal={() => setReportGeneration({ message: "none", error: "none", operation: "none" })}
                message={`Report ${reportGeneration.operation} is Successful`}
            />
            <FailureModal
                modal={reportGeneration.message === "Fail"}
                setModal={() => setReportGeneration({ message: "none", error: "none", operation: "none" })}
                message={reportGeneration?.error?.length > 0 ? "" : `Report ${reportGeneration.operation} Failed`}
                error={reportGeneration.error}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    allReports: state.report.allReports,
    reports: state.report.reports,
    reportDownloaded: state.report.reportDownloaded,
    accountCols: state.report.accountCols,
    dateRange: state.dashboard.dateRange,
    compareDateRange: state.dashboard.compareDateRange,
    filterChartMap: state.dashboard.filterChartMap,
    blends: state.blends.blends,
});

const mapDispatchToProps = (dispatch) => ({
    updateChart: (_) => dispatch(updateChart(_)),
    updateGrid: (_) => dispatch(updateGrid(_)),
    getTabDetails: (_) => dispatch(getTabDetails(_)),
    addReportData: (_) => dispatch(addReportData(_)),
    updateReportData: (_) => dispatch(updateReportData(_)),
    updateReportDateRange: (_) => dispatch(updateReportDateRange(_)),
    updateReportAdAccCols: (_) => dispatch(updateReportAdAccCols(_)),
    updateTab: (_) => dispatch(updateTab(_)),
    changeDate: (_) => dispatch(changeDate(_)),
    changeCompareDate: (_) => dispatch(changeCompareDate(_)),
    updateFilterChartMap: (_) => dispatch(updateFilterChartMap(_)),
    updateLoader: (_) => dispatch(updateLoader(_)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);