import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Clear, ExpandMore } from "@mui/icons-material";
import MetricList from "./MetricList";
import SingleMetricSettings from "./SingleMetricSettings";
import { Metric } from "../../models/chart";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DateDisplayFormatter, DisplayDateForPayload, calComparedDate } from "../Charts/ChartUtils";
import DateRangeModal from "../Modal/DateRangeModal";
import { ReactComponent as CalendarIcon } from "../../Assets/Icons/boxless-calendar.svg";

export default function EditChartType2({
  handleAllChartsForceReload,
  editDrawerState,
  currentMetricState,
  setCurrentMetricState,
  channel,
  accountCols,
  channelCols,
}) {
  const dispatch = useDispatch();
  const { chart, handleChartChange } = editDrawerState;
  const [chartDimensions, setChartDimensions] = React.useState(
    chart.dimensions?.length === 0 ? [null] : chart.dimensions ?? [null]
  );
  const [leftMetrics, setLeftMetrics] = React.useState(
    chart.leftMetrics.concat([null])
  );
  const [rightMetrics, setRightMetrics] = React.useState(
    chart.rightMetrics?.concat([null])
  );
  const [table, setTable] = React.useState(chart.table);
  const [dataLevel, setDataLevel] = React.useState({
    id: chart.dataLevel,
    name: chart.dataLevel,
  });
  const state = useSelector((state) => channel.getState(state));
  const tables = state.tables;
  const dataLevels = (state.dataLevels?.[table?.id] ?? []).map((level) => ({
    id: level,
    name: level,
  }));
  const baseMetrics = channel.isBlend
    ? channelCols.metrics
    : Array.isArray(state.metrics)
      ? state.metrics
      : state.metrics?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")]
      ?? [];
  const baseDimensions = channel.isBlend
    ? channelCols.dimensions
    : Array.isArray(state.dimensions)
      ? state.dimensions
      : state.dimensions?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")]
      ?? [];

  const customMetrics = accountCols
    ? accountCols.metrics
    : Array.isArray(state.customMetrics)
      ? state.customMetrics
      : state.customMetrics?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")]
      ?? [];

  const customDimensions = accountCols
    ? accountCols.dimensions
    : Array.isArray(state.customDimensions)
      ? state.customDimensions
      : state.customDimensions?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")]
      ?? [];
  const metrics = React.useMemo(() => {
    let metrics = baseMetrics.concat(customMetrics);
    metrics = [...new Map(metrics.map(metric => [metric.id, metric])).values()];
    return metrics;
  },[customMetrics,baseMetrics]);
  const dimensions = baseDimensions.concat(customDimensions);

  if (!currentMetricState.metric.channelType) {
    currentMetricState.metric.channelType = channel.type;
    currentMetricState.metric.validate();
  }

  const dashboardDateRange = useSelector(state => state.dashboard.dateRange);
  const [dateRange, setDateRange] = React.useState(chart.compareWith?.startsWith("Previous")
    ? calComparedDate(chart.compareWith, dashboardDateRange)
    : {
      label: "Custom date range",
      startDate: chart.compareWith?.split("_")[0],
      endDate: chart.compareWith?.split("_")[1],
    }
  );
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  const applyDate = (datePickerRange) => {
    setDateRange(datePickerRange);
    setIsDatePickerOpen(false);
    if (datePickerRange.startDate && datePickerRange.endDate) {
      handleChartChange(
        "compareWith",
        datePickerRange.label === "Custom date range"
          ? (DisplayDateForPayload(datePickerRange?.startDate) + "_" + DisplayDateForPayload(datePickerRange?.endDate))
          : datePickerRange.label
      );
    } else {
      handleChartChange("compareWith", "");
    }
  };

  React.useEffect(() => {
    if (table !== chart.table) {
      handleChartChange("table", table);
      {
        channel.type === "tiktok-ads" &&
          setDataLevel(dataLevels[table?.id]?.[0] ?? { id: "", name: "" });
      }
      setChartDimensions([null]);
      setLeftMetrics([null]);
      setRightMetrics(chart.rightMetrics ? [null] : null);
      // if (!metrics.length) {
      //   dispatch(channel.actions?.getColumns(table));
      // }
    }
  }, [table]);

  React.useEffect(() => {
    if (chartDimensions.length) {
      handleChartChange(
        "dimensions",
        chartDimensions.filter((dimension) => dimension && dimension.id)
      );
    }
  }, [chartDimensions]);

  React.useEffect(() => {
    handleChartChange(
      "leftMetrics",
      leftMetrics.filter((metric) => metric && metric.metric)
    );
  }, [leftMetrics]);

  React.useEffect(() => {
    handleChartChange(
      "rightMetrics",
      rightMetrics?.filter((metric) => metric && metric.metric)
    );
  }, [rightMetrics]);

  const handleSingleMetricSettings = (metric, axis, index) =>
    setCurrentMetricState({
      isOpen: true,
      metric: metric,
      axis: axis,
      index: index,
    });

  const updateFilter = (filter) => {
    if (currentMetricState.axis === "left") {
      const newMetrics = Array.from(leftMetrics);
      const metric = Metric.copy(newMetrics[currentMetricState.index]);
      metric.filter = filter;
      newMetrics[currentMetricState.index] = metric;
      setLeftMetrics(newMetrics);
      setCurrentMetricState({ ...currentMetricState, metric: metric });
    } else {
      const newMetrics = Array.from(rightMetrics);
      const metric = Metric.copy(newMetrics[currentMetricState.index]);
      metric.filter = filter;
      newMetrics[currentMetricState.index] = metric;
      setRightMetrics(newMetrics);
      setCurrentMetricState({ ...currentMetricState, metric: metric });
    }
  };

  const getDisplayFilters = () => {
    return leftMetrics
      .filter((metric) => metric && metric.metric)
      .map((metric, index) => {
        const filterString = metric.getFilterString();
        if (filterString) {
          return {
            index: index,
            axis: "left",
            string: filterString,
          };
        }
        return null;
      })
      .concat(
        rightMetrics
          ?.filter((metric) => metric && metric.metric)
          .map((metric, index) => {
            const filterString = metric.getFilterString();
            if (filterString) {
              return {
                index: index,
                axis: "right",
                string: filterString,
              };
            }
            return null;
          })
      )
      .filter((filter) => filter);
  };

  React.useEffect(() => {
    if (chart.dataLevel !== dataLevel.id) {
      handleChartChange("dataLevel", dataLevel?.id);
      setChartDimensions([null]);
      setLeftMetrics([null]);
      setRightMetrics(chart.rightMetrics ? [null] : null);
    }
  }, [dataLevel]);

  React.useEffect(() => {
    // setLeftMetrics(leftMetrics.filter(metric => !metric || metrics?.findIndex(m => m.id === metric?.metric?.id) > -1));
    // setRightMetrics(rightMetrics?.filter(metric => !metric || metrics?.findIndex(m => m.id === metric?.metric?.id) > -1));
    // setChartDimensions(chartDimensions.filter(dim => !dim || dimensions?.findIndex(d => d.id === dim?.id) > -1));
  }, [accountCols])

  const displayFilters = getDisplayFilters();

  const onDragEndLeftMetrics = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    destination.index =
      destination.index === leftMetrics.length - 1
        ? destination.index - 1
        : destination.index;
    if (destination.index === source.index) return;
    const newMetrics = Array.from(leftMetrics);
    const pop = newMetrics.splice(source.index, 1)[0];
    newMetrics.splice(destination.index, 0, pop);
    setLeftMetrics(newMetrics);
  };

  return (
    <div>
      {currentMetricState.isOpen ? (
        <SingleMetricSettings
          chart={chart}
          channel={channel}
          accountCols={accountCols}
          channelCols={channelCols}
          currentMetric={currentMetricState.metric}
          isKPI={chart.type === "KPI"}
          handleChartChange={handleChartChange}
          dimension={chart.dimensions ? chart.dimensions[0]?.name : null}
          onChange={(metric) => {
            if (currentMetricState.axis === "left") {
              const newMetrics = Array.from(leftMetrics);
              newMetrics[currentMetricState.index] = metric;
              if (
                metric &&
                currentMetricState.index === newMetrics.length - 1
              ) {
                newMetrics.push(null);
              }
              setLeftMetrics(newMetrics);
            } else {
              const newMetrics = Array.from(rightMetrics);
              newMetrics[currentMetricState.index] = metric;
              if (
                metric &&
                currentMetricState.index === newMetrics.length - 1
              ) {
                newMetrics.push(null);
              }
              setRightMetrics(newMetrics);
            }
            setCurrentMetricState({ ...currentMetricState, metric: metric });
          }}
          updateFilter={updateFilter}
          handleAllChartsForceReload={handleAllChartsForceReload}
          onClose={() =>
            setCurrentMetricState({ ...currentMetricState, isOpen: false })
          }
        />
      ) : (
        <div>
          <Accordion
            defaultExpanded={chart.title === ""}
            sx={{
              boxShadow: "none",
              "& .Mui-expanded": { marginBottom: " 0px" },
              width: "100%",
            }}
          >
            <AccordionSummary
              sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#000000",
                }}
              >
                Title
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px 0px" }}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #EAEAEC",
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #EAEAEC",
                  },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #EAEAEC",
                  },
                }}
                InputProps={{
                  sx: {
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    height: "40px"
                  },
                }}
                placeholder="Enter Title"
                style={{ width: "100%" }}
                id="outlined-basic"
                defaultValue={chart.title}
                onChange={(e) => handleChartChange("title", e.target.value)}
                variant="outlined"
                size="small"
              />
            </AccordionDetails>
          </Accordion>

          {/* <Accordion
            sx={{
              boxShadow: "none",
              marginBottom: "10px",
              width: "100%",
              '&:before': {
                display: 'none',
              },
            }}
            disableGutters
            elevation={0}
          >
            <AccordionSummary
              sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#00000",
                }}
              >
                Compare with
              </Typography>
            </AccordionSummary>

            <AccordionDetails style={{ padding: "10px 0px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #EAEAEC",
                  width: "92%",
                  borderRadius: "6px",
                  padding: "0px 16px",
                  cursor: "pointer",
                }}
                onClick={() => setIsDatePickerOpen(true)}
              >
                <IconButton
                  disableRipple
                  onClick={() => setIsDatePickerOpen(true)}
                  aria-label="delete"
                >
                  <CalendarIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  component="div"
                  style={{ lineHeight: "16px", marginLeft: "10px" }}
                >
                  <span
                    className="inter"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      color: "#585969",
                    }}
                  >
                    {dateRange?.startDate && dateRange?.endDate
                      ? `${DateDisplayFormatter(dateRange?.startDate)} -${" "}
                                        ${DateDisplayFormatter(
                        dateRange?.endDate
                      )}`
                      : "No Comparison"}
                  </span>
                </Typography>
              </div>
            </AccordionDetails>
          </Accordion> */}

          <Accordion
            defaultExpanded={true}
            sx={{
              boxShadow: "none",
              '&:before': {
                display: 'none',
              },
              width: "100%",
            }}
            disableGutters
            elevation={0}
            id="editWigetScroll"
          >
            <AccordionSummary
              sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#000000",
                }}
              >
                Widget Data
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "10px 0px" }}>
              {/* report type */}
              {tables && (
                <div>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#666666",
                    }}
                  >
                    Report type
                  </Typography>
                  <MetricList
                    channel={channel}
                    metric={table}
                    metrics={tables}
                    isEditable={true}
                    onClick={setTable}
                    defaultTitle="Add Report Type"
                  />
                  <br />
                </div>
              )}
              {tables && channel.type === "tiktok-ads" && (
                <div>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#666666",
                    }}
                  >
                    Data level
                  </Typography>
                  <MetricList
                    channel={channel}
                    metric={dataLevel}
                    metrics={dataLevels}
                    isEditable={true}
                    onClick={setDataLevel}
                    defaultTitle="Add Data Level"
                  />
                  <br />
                </div>
              )}

              {/* dimensions */}
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#666666",
                }}
              >
                Dimension
              </Typography>
              {chartDimensions.map((dimension, index) => (
                <MetricList
                  key={chartDimensions[0]?.id}
                  channel={channel}
                  metric={dimension}
                  metrics={dimensions}
                  disabled={leftMetrics.length > 2}
                  msg="To add dimension, select only one metric."
                  isEditable={true}
                  defaultTitle="Add New Dimension"
                  onClick={(dimension) => {
                    const newDimensions = Array.from(chartDimensions);
                    newDimensions[index] = dimension;
                    setChartDimensions(newDimensions);
                  }}
                  onDelete={() => {
                    setChartDimensions([null]);
                  }}
                />
              ))}

              {/* left metrics */}
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#666666",
                  marginTop: "20px",
                }}
              >
                {rightMetrics ? "LEFT AXIS" : "Metrics"}
              </Typography>
              <DragDropContext onDragEnd={onDragEndLeftMetrics}>
                <Droppable droppableId="1" direction="vertical">
                  {(droppableProvided) => (
                    <div
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {leftMetrics.map((metric, index) => {
                        return (
                          <Draggable
                            draggableId={`${index}`}
                            index={index}
                            disableInteractiveElementBlocking
                          >
                            {(draggableProvided) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                    // marginLeft: metric?.metric ? "20px" : "0px",
                                  }}
                                >
                                  <MetricList
                                    key={leftMetrics.length * (index + 1)}
                                    channel={channel}
                                    metric={metric}
                                    value={metric}
                                    metrics={metrics}
                                    disabled={chartDimensions.filter(dim => dim).length > 0 && leftMetrics.length > 1}
                                    msg="To add more metrics, remove the dimension."
                                    onClick={(metric) => {
                                      if (index === leftMetrics.length - 1) {
                                        const newMetrics = Array.from(leftMetrics);
                                        newMetrics[index] = Metric.new(chart, metric);
                                        newMetrics.push(null);
                                        setLeftMetrics(newMetrics);
                                      } else {
                                        handleSingleMetricSettings(metric, "left", index);
                                      }
                                    }}
                                    draggableProvided={draggableProvided}
                                    onDelete={() => {
                                      const newMetrics = Array.from(leftMetrics);
                                      if (newMetrics.length === 1) {
                                        setLeftMetrics([null]);
                                      } else {
                                        setLeftMetrics(newMetrics.slice(0, index).concat(newMetrics.slice(index + 1)));
                                      }
                                    }}
                                    isColumnThere = {metric ? (metrics?.find(m => m.id === metric?.metric?.id) ?? false) : true}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {droppableProvided ? droppableProvided.placeholder : null}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {/* right metrics */}
              {rightMetrics && (
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#666666",
                    marginTop: "25px",
                  }}
                >
                  RIGHT AXIS
                </Typography>
              )}
              {rightMetrics?.map((metric, index) => (
                <MetricList
                  key={rightMetrics.length * (index + 1)}
                  channel={channel}
                  metric={metric}
                  metrics={metrics}
                  onClick={(metric) => {
                    if (index === rightMetrics.length - 1) {
                      const newMetrics = Array.from(rightMetrics);
                      newMetrics[index] = Metric.new(chart, metric);
                      newMetrics.push(null);
                      setRightMetrics(newMetrics);
                    } else {
                      handleSingleMetricSettings(metric, "right", index);
                    }
                  }}
                  onDelete={() => {
                    const newMetrics = Array.from(rightMetrics);
                    setRightMetrics(
                      newMetrics
                        .slice(0, index)
                        .concat(newMetrics.slice(index + 1))
                    );
                  }}
                  isColumnThere = {metric ? (metrics?.find(m => m.id === metric?.metric?.id) ?? false) : true}
                />
              ))}

              {/* filter display */}
              {displayFilters.length > 0 && (
                <h4 className="inter bold" style={{ marginTop: "25px" }}>
                  FILTERS
                </h4>
              )}
              {displayFilters.map((filter, index) => (
                <div
                  key={index}
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div className="filter-list">{filter.string}</div>
                  <IconButton
                    onClick={() => {
                      if (filter.axis === "left") {
                        const newMetrics = Array.from(leftMetrics);
                        newMetrics[filter.index] = Metric.new(
                          chart,
                          newMetrics[filter.index].metric
                        );
                        setLeftMetrics(newMetrics);
                      } else {
                        const newMetrics = Array.from(rightMetrics);
                        newMetrics[filter.index] = Metric.new(
                          chart,
                          newMetrics[filter.index].metric
                        );
                        setRightMetrics(newMetrics);
                      }
                    }}
                    disableRipple
                    disableFocusRipple
                    aria-label="delete"
                  >
                    <Clear />
                  </IconButton>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      {isDatePickerOpen && <DateRangeModal
        compare={true}
        dateRange={dashboardDateRange}
        compareDateRange={dateRange}
        changeCompareDate={applyDate}
        isDatePickerOpen={isDatePickerOpen}
        setIsDatePickerOpen={setIsDatePickerOpen}
      />}
    </div>
  );
}