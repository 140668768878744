import { isArray } from "highcharts";
import { REPORT_LEVEL, Report, UPDATE_TEMPLATE_ID } from "../../models/report";
import {
  CREATE_REPORT_SUCCESS,
  DELETE_REPORT_SUCCESS,
  GET_ALL_REPORTS_SUCCESS,
  GET_REPORT,
  GET_REPORT_FAILURE,
  GET_REPORT_SUCCESS,
  UPDATE_CHART_LOCAL,
  UPDATE_GRID_LOCAL,
  UPDATE_TAB_LOCAL,
  UPDATE_CHART_SUCCESS,
  UPDATE_REPORT_SUCCESS,
  GET_DEFAULT_REPORT,
  GET_DEFAULT_REPORT_SUCCESS,
  GET_DEFAULT_REPORT_FAILURE,
  SET_DEFAULT_REPORT_SUCCESS,
  GET_TAB_SUCCESS,
  MAINTAIN_REPORT_UPDATES,
  CLEAR_REPORT_UPDATES,
  UPDATE_REPORT_DATE_RANGE,
  UPDATE_REPORT_AD_ACC_COLS,
  GET_SHARED_REPORT_SUCCESS,
  GET_SHARED_REPORT_FAILURE,
  GET_REPORT_DOWNLOADED_SUCCESS,
} from "./type";

const INITIAL_STATE = {
  allReports: [],
  reports: {}, //{...state.reports, [key] : updates }
  reportDownloaded: false,
  defaultReportId: null,
  updates: [], // this is only to push updates on SAVE call
  accountCols: {},
  sharedReports: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  let currentStateReport = JSON.parse(JSON.stringify(state.reports));
  let currentAllReports = JSON.parse(JSON.stringify(state.allReports));
  switch (action.type) {
    // case CREATE_REPORT_SUCCESS:
    //   const report = action.payload.report;
    //   const id = action.payload.id;
    //   const reportJSON = report.toJSON();
    //   reportJSON._id = id;
    //   return {
    //     ...state,
    //     allReports: state.allReports.concat({
    //       id: id,
    //       report_name: report.name,
    //       report_type: report.type,
    //       chart_count: reportJSON.charts.length,
    //     }),
    //     reports: { ...state.reports, [id]: reportJSON },
    //   };
    case UPDATE_REPORT_SUCCESS:
      const newAllReport = Array.from(state.allReports);
      const pos = newAllReport.findIndex(
        (e) => e.id === action.payload.reportId
      );
      newAllReport[pos] = { ...newAllReport[pos], ...action.payload.params };
      const reportUpdate = !!state.reports[action.payload.reportId];
      return {
        ...state,
        allReports: [...newAllReport],
        reports: reportUpdate
          ? {
              ...state.reports,
              [action.payload.reportId]: {
                ...state.reports[action.payload.reportId],
                ...action.payload.params,
                updated_at: new Date().getTime(),
              },
            }
          : state.reports,
      };
    case DELETE_REPORT_SUCCESS:
      const newReports = { ...state.reports };
      delete newReports[action.payload.id];
      // const newAllReports = Array.from(state.allReports)
      // const index = newAllReports.findIndex(e => e.id === action.payload.id)
      // if (index !== -1) newAllReports.splice(index, 1)
      return { ...state, reports: { ...newReports } }; // allReports: [...newAllReports],
    case GET_ALL_REPORTS_SUCCESS:
      return { ...state, allReports: action.payload };
    case GET_REPORT:
      return { ...state, reportDownloaded: false };
    case GET_REPORT_SUCCESS:
      const reportIndex = currentAllReports.findIndex(
        (report) =>
          report.report_id === action.payload.report?.reports?.report_id
      );
      if (reportIndex !== -1) {
        currentAllReports[reportIndex] = {
          ...currentAllReports[reportIndex],
          ...(action.payload.report?.reports ?? {}),
        };
      } else {
        currentAllReports.push(action.payload.report?.reports ?? {});
      }
      return {
        ...state,
        allReports: currentAllReports,
        reports: {
          ...state.reports,
          [action.payload.id]: Report.getTabDetailsObj(action.payload.report),
        },
      };
    case GET_REPORT_DOWNLOADED_SUCCESS:
      return { ...state, reportDownloaded: true };
    case GET_REPORT_FAILURE:
      return { ...state, reportDownloaded: true };
    case GET_SHARED_REPORT_SUCCESS:
      return {
        ...state,
        sharedReports: {
          ...state.sharedReports,
          [action.payload.shareReportId]: { reportId: action.payload.reportId },
        },
      };
    case GET_SHARED_REPORT_FAILURE:
      return {
        ...state,
        sharedReports: {
          ...state.sharedReports,
          [action.payload.shareReportId]: { ...action.payload.params },
        },
      };
    case GET_TAB_SUCCESS:
      const { data, customColumns } = action.payload;

      function updateMetrics(metrics, customColumns) {
        return metrics.map((metric) => {
          if (metric.group === "Customized") {
            const customColumnMetadata = customColumns.find(
              (c) => c.id === metric.id
            );
            if (customColumnMetadata) {
              return {
                ...metric,
                formula: customColumnMetadata.other.nameFormula,
                name: customColumnMetadata.name,
                metricType: customColumnMetadata.other.metricType.id,
                dataType: customColumnMetadata.other.dataType.id,
              };
            }
          }
          return metric;
        });
      }
      if (action.payload.data) {
        const newData = data.map((grid) => ({
          ...grid,
          charts: grid.charts.map((chart) => ({
            ...chart,
            left_metrics: chart.left_metrics?.length
              ? updateMetrics(chart.left_metrics, customColumns)
              : chart.left_metrics,
            right_metrics: chart.right_metrics?.length
              ? updateMetrics(chart.right_metrics || [], customColumns)
              : chart.right_metrics,
          })),
        }));

        return {
          ...state,
          reports: {
            ...state.reports,
            [action.payload.reportId]: {
              ...state.reports[action.payload.reportId],
              [action.payload.tabId]: {
                ...(state.reports[action.payload.reportId]?.[
                  action.payload.tabId
                ] ?? {}),
                grids: newData,
              },
            },
          },
        };
      } else {
        return state;
      }
    case GET_DEFAULT_REPORT_SUCCESS:
      const reportId = action.payload.result;
      return { ...state, defaultReportId: reportId };
    case GET_DEFAULT_REPORT_FAILURE:
      return { ...state, defaultReportId: null };
    case SET_DEFAULT_REPORT_SUCCESS:
      const setReportId = action.payload.result;
      return { ...state, defaultReportId: setReportId };
    case UPDATE_CHART_LOCAL:
      let currentGrids =
        currentStateReport[action.payload.report_id][action.payload.tab_id]
          .grids;
      var gridIndex = currentGrids.findIndex(
        (grid) => grid.grid_id === action.payload.grid_id
      );
      if (gridIndex === -1) {
        console.log(" ~err : gridIndex not found!");
        break;
      }
      action.payload.updates.forEach((update) => {
        switch (update.update_type) {
          case "update":
            let chartIndex = currentGrids[gridIndex].charts.findIndex(
              (chart) => chart.chart_id === update.chart_id
            );
            if (chartIndex === -1) {
              console.log(" ~err : chartIndex not found!");
              break;
            }
            currentGrids[gridIndex].charts[chartIndex] = {
              ...currentGrids[gridIndex].charts[chartIndex],
              ...update.params,
            };
            break;
          case "delete":
            currentGrids[gridIndex].charts = currentGrids[
              gridIndex
            ].charts.filter((chart) => chart.chart_id !== update.chart_id);
            break;
          case "add":
            currentGrids[gridIndex].charts.push(update.params);
            break;
        }
      });
      currentStateReport[action.payload.report_id][
        action.payload.tab_id
      ].grids = currentGrids;
      return { ...state, reports: currentStateReport };
    case UPDATE_GRID_LOCAL:
      action.payload.updates.forEach((update) => {
        var newGrids =
          currentStateReport[action.payload.report_id][update.tab_id].grids;
        switch (update.update_type) {
          case "update":
            const index = newGrids.findIndex(
              (grid) => grid.grid_id === update.grid_id
            );
            newGrids[index] = { ...newGrids[index], ...update.params };
            break;
          case "delete":
            newGrids = newGrids.filter(
              (grid) => grid.grid_id !== update.grid_id
            );
            break;
          case "add":
            newGrids.push(update.params);
            break;
        }
        currentStateReport[action.payload.report_id][update.tab_id].grids =
          newGrids;
      });
      return { ...state, reports: currentStateReport };

    case UPDATE_TAB_LOCAL:
      /**
       * updates: [{update_type: "update", tab_id: 2323, params: {}}]
       */
      action.payload.updates.forEach((update) => {
        switch (update.update_type) {
          case "update":
            let existingTabObj =
              currentStateReport[action.payload.report_id][update.tab_id];
            currentStateReport[action.payload.report_id][update.tab_id] = {
              ...existingTabObj,
              ...update.params,
            };
            break;
          case "delete":
            delete currentStateReport[action.payload.report_id][update.tab_id];
            break;
          case "add":
            currentStateReport[action.payload.report_id][update.tab_id] =
              update.params;
            break;
        }
      });
      return { ...state, reports: currentStateReport };

    case CLEAR_REPORT_UPDATES:
      const newAllReports = Array.from(state.allReports);
      if (action.payload?.updates) {
        const index = newAllReports.findIndex(
          (e) => e.report_id === action.payload.reportId
        );
        newAllReports[index] = {
          ...newAllReports[index],
          ...action.payload.updates,
        };
      }
      return {
        ...state,
        allReports: newAllReports, //update latest time and username in redux until updated fetched from backend
        updates: INITIAL_STATE.updates, //clear all old updates state
      };

    case UPDATE_REPORT_DATE_RANGE:
      const tempReport = currentAllReports.find(
        (report) => report.report_id === action.payload.report_id
      );
      tempReport.date_frequency = action.payload.date_frequency;
      const tempUpdtes = action.payload;
      const tempCurrentState = JSON.parse(JSON.stringify(state.updates));
      const newObj = [];
      let foundIndex = -1;
      foundIndex = tempCurrentState?.findIndex?.((updateObj) => {
        return (
          updateObj.level === "REPORT" &&
          updateObj.report_id === tempUpdtes?.report_id
        );
      });
      if (foundIndex >= 0) {
        tempCurrentState[foundIndex].data = {
          ...tempCurrentState[foundIndex]?.data,
          date_frequency: action.payload.date_frequency,
        };
      } else {
        newObj.push({
          template_id: UPDATE_TEMPLATE_ID,
          report_id: tempUpdtes?.report_id,
          _report_id: tempUpdtes?._report_id,
          level: "REPORT",
          type: "UPDATE",
          data: {
            ...state?.updates?.data,
            date_frequency: action.payload.date_frequency,
          },
        });
      }

      return {
        ...state,
        allReports: currentAllReports,
        updates: isArray(tempCurrentState)
          ? [...tempCurrentState, ...newObj]
          : [...newObj],
      };
    case UPDATE_REPORT_AD_ACC_COLS:
      return {
        ...state,
        accountCols: action.payload.reset
          ? {}
          : { ...state.accountCols, ...action.payload },
      };
    case MAINTAIN_REPORT_UPDATES:
      const tempUpdtesPayload = action.payload;
      const tempCurrentStateUpdates = JSON.parse(JSON.stringify(state.updates));
      const newObjUpdates = tempCurrentStateUpdates;
      tempUpdtesPayload.forEach((payloadObj) => {
        let foundIndex = tempCurrentStateUpdates.findIndex((updateObj) => {
          return (
            ((updateObj.level === REPORT_LEVEL.TAB &&
              updateObj.tab_id === payloadObj.tab_id) ||
              (updateObj.level === REPORT_LEVEL.GRID &&
                updateObj.grid_id === payloadObj.grid_id) ||
              (updateObj.level === REPORT_LEVEL.CHART &&
                updateObj.chart_id === payloadObj.chart_id)) &&
            updateObj.type === payloadObj.type
          );
        });
        if (foundIndex !== -1) {
          newObjUpdates[foundIndex].data = {
            ...newObjUpdates[foundIndex].data,
            ...payloadObj.data,
          };
        } else {
          newObjUpdates.push(payloadObj);
        }
      });
      return { ...state, updates: newObjUpdates };
    default:
      return state;
  }
};

export default reducer;
