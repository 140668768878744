import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CREATE_REPORT, CREATE_REPORT_FAILURE, CREATE_REPORT_SUCCESS,
  UPDATE_REPORT, UPDATE_REPORT_SUCCESS, UPDATE_REPORT_FAILURE,
  DELETE_REPORT, DELETE_REPORT_SUCCESS, DELETE_REPORT_FAILURE,
  GET_ALL_REPORTS, GET_ALL_REPORTS_SUCCESS, GET_ALL_REPORTS_FAILURE,
  GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_FAILURE,
  UPDATE_CHART, UPDATE_CHART_LOCAL, UPDATE_CHART_SUCCESS, UPDATE_CHART_FAILURE,
  UPDATE_GRID, UPDATE_GRID_LOCAL, UPDATE_GRID_SUCCESS,
  UPDATE_TAB, UPDATE_TAB_LOCAL, UPDATE_TAB_SUCCESS,
  DUPLICATE_REPORT, DUPLICATE_REPORT_SUCCESS, DUPLICATE_REPORT_FAILURE,
  DUPLICATE_TAB_SUCCESS, DUPLICATE_TAB_FAILURE,
  GET_DEFAULT_REPORT, GET_DEFAULT_REPORT_SUCCESS, GET_DEFAULT_REPORT_FAILURE,
  SET_DEFAULT_REPORT, SET_DEFAULT_REPORT_SUCCESS, SET_DEFAULT_REPORT_FAILURE,
  GET_TAB_DETAILS, GET_TAB_SUCCESS, GET_TAB_FAILURE,
  ADD_REPORT_DATA, UPDATE_REPORT_DATA, CLEAR_REPORT_UPDATES, MAINTAIN_REPORT_UPDATES,
  SHARE_REPORT, SHARE_REPORT_SUCCESS, SHARE_REPORT_FAILURE, GET_SHARED_REPORT, GET_SHARED_REPORT_SUCCESS, GET_SHARED_REPORT_FAILURE, GET_REPORT_DOWNLOADED_SUCCESS,
} from "./type";
import { Tab as ReportTab, Grid as ReportGrid, UPDATE_TEMPLATE_ID, REPORT_LEVEL } from "../../models/report";
import { Chart } from "../../models/chart";
import { api } from "..";
import { UPDATE_LOADER } from "../user/type";
import { EVENTS, transformMixPanelData } from "../../utils/mixpanel";
import { updateLoader } from "../user/action";
import { CHECK_AUTH_SUCCESS } from "../channel/type";

function* shareReport(args) {
  try {
    const { report, emails, loading, sharedId } = args.payload || {};
    yield api.post(`/v2/api/audiences/report/shared-report/${sharedId}/fire-mails`, {
      emails, reportName: report.report_name
    });
    yield put({ type: SHARE_REPORT_SUCCESS });
    if (loading) {
      yield loading("Success");
    }
  } catch (error) {
    yield put({ type: SHARE_REPORT_FAILURE, payload: error });
    if (args.payload?.loading) {
      yield args.payload.loading("Fail");
    }
  }
}

function* getSharedReportDetails(args) {
  const { shareReportId, callback } = args.payload || {};
  const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
  try {
    const response = yield api.get(`/v2/external-access/report/share/${shareReportId}`, false);
    const { access_token, report_id } = response.data.data ?? {};

    if (!isAuthenticated) {
      yield localStorage.setItem("accessToken", access_token);
    }
    yield getReport({ payload: { id: report_id, updateAccs: !isAuthenticated, callback } })
    yield put({ type: GET_SHARED_REPORT_SUCCESS, payload: { shareReportId, reportId: report_id } });

  } catch (error) {
    yield put({
      type: GET_SHARED_REPORT_FAILURE,
      payload: {
        shareReportId, params: {
          deleted: error?.response?.data?.message === "Report doesn't exist",
          expired: (error?.response?.data?.message ?? "").includes("Report is expired"),
          failed: true
        }
      }
    });
    if (!isAuthenticated) { yield localStorage.clear(); }
  }
}

export const getShareId = ({ report_id }) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`/v2/api/audiences/report/${report_id}/share-report`);
      resolve(response.data.data);
    } catch (error) {
      reject(error.response);
    }
  });


function* createReport(args) {
  try {
    const report = args.payload.report;
    yield put(updateLoader(true));
    const response = yield api.post("/v2/api/audiences/report", {
      ...(report.toJSON() || {}),
      template_id: 1,
    });
    yield put({ type: CREATE_REPORT_SUCCESS });
    yield call(getAllReports);
    yield put(updateLoader(false));
    if (args?.payload?.loading) {
      yield args.payload.loading("Success");
    }
    // transformMixPanelData(EVENTS.migrate_reports_success, args?.payload);
    // window.location.href = `/dashboard?report_id=${id}&report_name=${reportName}`
  } catch (error) {
    yield put({ type: CREATE_REPORT_FAILURE, payload: error });
    yield put(updateLoader(false));
    if (args.payload.loading) {
      yield args.payload.loading("Fail", error?.response?.data?.message ?? "");
    }
  }
}

function* updateReport(args) {
  try {
    yield put(updateLoader(true));
    const { id, reportId } = args.payload;
    yield api.put(`/v2/api/audiences/report/${reportId}`, [{
      template_id: 1,
      report_id: reportId,
      _report_id: id,
      level: REPORT_LEVEL.REPORT,
      type: "UPDATE",
      data: args.payload.params
    }]);

    yield put({
      type: UPDATE_REPORT_SUCCESS,
      payload: args.payload,
    });
    if (args.payload.refetch) {
      yield getAllReports();
    }
    yield put(updateLoader(false));
    if (args?.payload?.loading) {
      yield args?.payload?.loading?.("Success");
    }
  } catch (error) {
    yield put({
      type: UPDATE_REPORT_FAILURE,
    });
    yield put(updateLoader(false));
    if (args.payload.loading) {
      yield args.payload.loading("Fail");
    }
  }
}

/**
 *
 * @param {{payload: {id: number, reportId: string, reload: boolean}, type: string}} args
 */
function* deleteReport(args) {
  try {
    const { id, reportId } = args.payload;
    yield api.delete(`/v2/api/audiences/report/${reportId}`);
    if (args.payload.delete) {
      yield setDefaultReport({
        payload: { defaultReportId: null },
      });
    }
    yield put({
      type: DELETE_REPORT_SUCCESS,
      payload: { id: id },
    });
    yield getAllReports();
    if (args.payload.reload) {
      yield (window.location.href = "/report-management");
    }
    args.payload.callback?.();
  } catch (error) {
    yield put({
      type: DELETE_REPORT_FAILURE,
    });
  }
}

export const getReportsList = ({ id }) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await api.post("/v2/analytics/report/list", {
        organization_id: id,
      });
      resolve(response.data.data);
    } catch (error) {
      reject(error.response);
    }
  });

export const getReportData = ({ id }) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`/v2/analytics/report/get/${id}`);
      resolve(response.data.data);
    } catch (error) {
      reject(error.response);
    }
  });

function* getAllReports(args) {
  try {
    const response = yield api.get(
      "/v2/api/audiences/reports?orderBy=createdAt&order=DESC&page=1&limit=150"
    );
    const reports = response.data.data;
    yield put({
      type: GET_ALL_REPORTS_SUCCESS,
      payload: reports,
    });
  } catch (error) {
    yield put({
      type: GET_ALL_REPORTS_FAILURE,
    });
    if (error.code === "ERR_NETWORK") {
      yield put({
        type: "SERVICE_DOWN",
      });
    }
  }
  args?.payload?.loading?.();
}

/**
 *
 * @param {{payload: {id: number}, type: string}} args
 * id is reportId
 */
function* getReport(args) {
  const id = args.payload.id;
  try {
    const response = yield api.get(`/v2/api/audiences/report/${id}/tabs`);
    let report = response.data.data;

    yield put({
      type: GET_REPORT_SUCCESS,
      payload: { id: id, report },
    });

    yield all(report?.tabs?.map(tab => getTab({
      payload: {
        reportId: id,
        tabId: tab.tab_id,
      }
    })))

    yield put({
      type: GET_REPORT_DOWNLOADED_SUCCESS,
      payload: { id: id, report },
    });

    if (args.payload.updateAccs) {
      //Assign the connected accounts fetched in the report to authenticated accounts
      yield put({
        type: CHECK_AUTH_SUCCESS,
        payload: report.reports.audiences.reduce((channels, account) => {
          channels[account.channelType ?? account.source] = true;
          return channels;
        }, {})
      });
    }

    // pushing tab_id and tab_name in url
    if (args.payload.callback) {
      args.payload.callback(
        report?.tabs?.[0]?.tab_id,
        report?.tabs?.[0]?.title
      );
    }
    if (args.payload.update) {
      yield args.payload.update(report);
    }
  } catch (error) {
    yield put({
      type: GET_REPORT_FAILURE,
    });
    if (error.code === "ERR_NETWORK") {
      yield put({
        type: "SERVICE_DOWN",
      });
    }
  }
}

/**
 *
 * @param {{payload: {reportId: number, tabId: string}}} args
 * tabId will be tab_id from report which is UUID
 */
function* getTab(args) {
  const { tabId, reportId } = args.payload;
  try {
    const response = yield api.get(
      `/v2/api/audiences/report/${reportId}/tabs/${tabId}`
    );
    const customColumnsRes = yield api.post("/v2/analytics/customized-columns/get/all");
    const customColumns = customColumnsRes.data.data;
    
    yield put({
      type: GET_TAB_SUCCESS,
      payload: { reportId, tabId, data: response.data.data, customColumns },
    });
  } catch (error) {
    yield put({
      type: GET_TAB_FAILURE,
    });
    if (error.code === "ERR_NETWORK") {
      yield put({
        type: "SERVICE_DOWN",
      });
    }
  }
}

const sendToMixpanel = (reports, args, isEditWidgetOrSave) => {
  if (isEditWidgetOrSave === "CREATE_NEW_CHART") {
    transformMixPanelData(EVENTS.add_new_chart, args.payload);
  } else if (isEditWidgetOrSave === "UPDATE_CHART") {
    transformMixPanelData(EVENTS.edit_chart, args.payload);
  } else if (isEditWidgetOrSave === "REMOVE_CHART") {
    const charts = reports.reports[args.payload.reportId].charts;
    const chart_id = args.payload.updates[0].chart_id;
    const data = charts.find((chart) => chart.id === chart_id);
    const temp = { ...args.payload };
    temp["updates"][0]["params"] = data;
    transformMixPanelData(EVENTS.delete_chart, temp);
  }
};

function* updateChart(args) {
  if (args.payload.updates.length === 0) {
    return;
  }
  try {
    const isEditWidgetOrSave = yield select(
      (state) => state.channel.isSaveAndUpdateClick
    );
    const reports = yield select((state) => state.report);
    yield put({
      type: UPDATE_CHART_LOCAL,
      payload: args.payload,
    });
    // yield api.post( `/v2/analytics/report/${args.payload.reportId}/chart/update`, { updates: args.payload.updates, } );
    yield put({
      type: MAINTAIN_REPORT_UPDATES,
      payload: args.payload.updates.map(chart => ({
        template_id: UPDATE_TEMPLATE_ID,
        chart_id: chart.chart_id,
        _chart_id: chart.id,
        level: REPORT_LEVEL.CHART,
        type: chart.update_type.toUpperCase(),
        data: chart.params
      })),
    })
    yield put({
      type: UPDATE_CHART_SUCCESS,
      payload: args.payload,
    });
    sendToMixpanel(reports, args, isEditWidgetOrSave);
  } catch (error) {
    yield put({
      type: UPDATE_CHART_FAILURE,
      payload: error,
    });
  }
}

/**
 *
 * @param {{payload: {reportId: string}}} args
 * reportId will be report_id from report which is UUID
 */
function* updateReportData(args) {
  try {
    const { reportId, updates, stopLoading } = args.payload;
    const reports = yield select((state) => state.report);
    const updatesForReport = reports.updates || [];
    const response = yield api.put(`/v2/api/audiences/report/${reportId}`, updatesForReport);

    yield put({
      type: CLEAR_REPORT_UPDATES,
      payload: { reportId, updates }
    })
    yield stopLoading();
    // yield window.location.reload();

  } catch (error) {
    console.log(
      "🚀 ~ file: saga.js ~ line 139 ~ function*updateReport ~ error",
      error
    );
    yield args.payload.stopLoading();
  }
}

/**
 *
 * @param {{payload: {level: string, type: string, report_id: string, tabId: string, gridId: string, data: object}}} args
 */
function* addReportData(args) {
  try {
    const dataPayload = { ...(args.payload || {}) };
    const reportId = dataPayload.report_id;
    dataPayload.template_id = 2;
    dataPayload.type = 'ADD';
    const response = yield api.put(`/v2/api/audiences/report/${reportId}`, [dataPayload]);

    const responseData = response.data.data[0];
    const tempUpdatesPayload = [
      responseData.tab
        ? {
          template_id: UPDATE_TEMPLATE_ID,
          level: REPORT_LEVEL.TAB,
          tab_id: responseData.tab.tab_id,
          _tab_id: responseData.tab.id,
          type: "UPDATE",
          data: responseData.tab
        }
        : [],
      responseData.grid
        ? {
          template_id: UPDATE_TEMPLATE_ID,
          level: REPORT_LEVEL.GRID,
          grid_id: responseData.grid.grid_id,
          _grid_id: responseData.grid.id,
          type: "UPDATE",
          data: responseData.grid
        }
        : [],
      responseData.charts.map(chart => (
        {
          template_id: UPDATE_TEMPLATE_ID,
          chart_id: chart.chart_id,
          _chart_id: chart.id,
          level: REPORT_LEVEL.CHART,
          type: "UPDATE",
          data: chart
        }
      ))
    ];

    yield put({
      type: MAINTAIN_REPORT_UPDATES,
      payload: tempUpdatesPayload.flat(),
    })

    if (responseData.tab) {
      yield put({
        type: UPDATE_TAB_LOCAL,
        payload: {
          report_id: dataPayload._report_id,
          updates: [{
            update_type: "add",
            tab_id: responseData.tab.tab_id,
            params: {
              ...responseData.tab, grids: [{ ...responseData.grid, charts: responseData.charts }]
            }
          }]
        },
      });
    } else if (responseData.grid) {
      yield put({
        type: UPDATE_GRID_LOCAL,
        payload: {
          report_id: dataPayload._report_id,
          updates: [{
            update_type: "add",
            tab_id: dataPayload.tab_id,
            grid_id: responseData.grid.grid_id,
            params: {
              ...responseData.grid, charts: responseData.charts
            }
          }]
        },
      });
    } else if (responseData.charts) {
      yield put({
        type: UPDATE_CHART_LOCAL,
        payload: {
          report_id: dataPayload._report_id,
          tab_id: dataPayload.tab_id,
          grid_id: dataPayload.grid_id,
          updates: responseData.charts.map(chart => ({
            update_type: "add",
            tab_id: dataPayload.tab_id,
            grid_id: dataPayload.grid_id,
            params: chart
          }))
        },
      });
    }

  } catch (error) {
    console.log(
      "🚀 ~ file: saga.js ~ line 139 ~ function*updateReportByAdd ~ error",
      error
    );
  }
}

function* updateGrid(args) {
  if (args.payload.updates.length === 0) {
    return;
  }
  try {
    yield put({
      type: UPDATE_GRID_LOCAL,
      payload: args.payload,
    });
    // yield api.post( `/v2/analytics/report/${args.payload.reportId}/grid/update`, { updates: args.payload.updates, } );
    yield put({
      type: MAINTAIN_REPORT_UPDATES,
      payload: args.payload.updates.map(grid => ({
        template_id: UPDATE_TEMPLATE_ID,
        grid_id: grid.grid_id,
        _grid_id: grid.id,
        level: REPORT_LEVEL.GRID,
        type: grid.update_type.toUpperCase(),
        data: grid.params
      })),
    })
    yield put({
      type: UPDATE_GRID_SUCCESS,
      payload: args.payload,
    });
  } catch (error) {
    console.log(
      "🚀 ~ file: saga.js ~ line 157 ~ function*updateGrid ~ error",
      error
    );
  }
}

function* updateTab(args) {
  if (args.payload.updates.length === 0) {
    return;
  }
  try {
    yield put({
      type: UPDATE_TAB_LOCAL,
      payload: args.payload,
    });
    // yield api.post(`/v2/analytics/report/${args.payload.reportId}/tab/update`, { updates: args.payload.updates, });
    yield put({
      type: MAINTAIN_REPORT_UPDATES,
      payload: args.payload.updates.map(tab => ({
        template_id: UPDATE_TEMPLATE_ID,
        tab_id: tab.tab_id,
        _tab_id: tab.id,
        level: REPORT_LEVEL.TAB,
        type: tab.update_type.toUpperCase(),
        data: tab.params
      })),
    })
    yield put({
      type: UPDATE_TAB_SUCCESS,
      payload: args.payload,
    });
    if (args.payload.navigate) {
      yield call(args.payload.navigate);
    }
  } catch (error) {
    console.log(
      "🚀 ~ file: saga.js ~ line 176 ~ function*updateTab ~ error",
      error
    );
  }
}

function* duplicateTab(args) {
  try {
    let { report, destReport, tab, index, destTabs } = args || {};
    const sourceTab = report.tabs.find((t) => t.id === tab.id);
    tab = new ReportTab(
      tab.newName || tab.name,
      destTabs.length + index,
      sourceTab.grids
    );
    const grids = tab.grids.map((grid) => {
      return new ReportGrid(
        tab.id,
        grid.title,
        grid.subtitle,
        grid.charts,
        grid.gridStyle
      );
    });
    const charts = grids.reduce(
      (allCharts, grid) =>
        allCharts.concat(
          grid.charts.map((chart) => {
            return new Chart(
              chart.channelType,
              chart.title,
              chart.type,
              chart.table,
              chart.gridPosition,
              chart.leftMetrics,
              chart.rightMetrics,
              chart.dimensions,
              chart.filter,
              chart.account,
              chart.chartStyle,
              grid.id, // updated parent grid id
              chart.compareWith
            );
          })
        ),
      []
    );

    yield all([
      updateTab({
        payload: {
          reportId: destReport.id,
          updates: [
            { tab_id: tab.id, update_type: "add", params: tab.toJSON() },
          ],
        },
      }),
      updateGrid({
        payload: {
          reportId: destReport.id,
          updates: grids.map((grid) => {
            return {
              grid_id: grid.id,
              update_type: "add",
              params: grid.toJSON(),
            };
          }),
        },
      }),
      updateChart({
        payload: {
          reportId: destReport.id,
          updates: charts.map((chart) => {
            return {
              chart_id: chart.id,
              update_type: "add",
              params: chart.toJSON(),
            };
          }),
        },
      }),
    ]);
    yield put({
      type: DUPLICATE_TAB_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: DUPLICATE_TAB_FAILURE,
    });
    console.log(
      "🚀 ~ file: saga.js ~ line 219 ~ function*duplicateTab ~ error",
      error
    );
    throw error;
  }
}

function* duplicateReport(args) {
  try {
    const { report, destReport, tabs, destTabs } = args.payload || {};
    yield all(
      tabs.map((tab, index) =>
        duplicateTab({ report, destReport, tab, index, destTabs })
      )
    );
    yield put({
      type: DUPLICATE_REPORT_SUCCESS,
    });
    yield args.payload.loading("Success");
    window.location.href = `/dashboard/${destReport.id}`;
  } catch (error) {
    yield put({
      type: DUPLICATE_REPORT_FAILURE,
    });
    yield args.payload.loading("Fail");
  }
}

function* getDefaultReport(args) {
  if (args?.payload?.loading) {
    yield put({
      type: UPDATE_LOADER,
      payload: true,
    });
  }
  try {
    const response = yield api.post(
      "/v2/analytics/users/default-report/",
      {},
      true
    );
    yield put({
      type: GET_DEFAULT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GET_DEFAULT_REPORT_FAILURE,
    });
  }
  if (args?.payload?.loading) {
    yield put({
      type: UPDATE_LOADER,
      payload: false,
    });
  }
}

function* setDefaultReport(args) {
  try {
    const response = yield api.post(
      "/v2/analytics/users/update-default-report/",
      {
        // email: args.payload.email,
        defaultReportId: args.payload.defaultReportId,
      },
      true
    );
    yield put({
      type: SET_DEFAULT_REPORT_SUCCESS,
      payload: response.data,
    });
    if (args?.payload?.loading) {
      yield args.payload.loading("Report has been set to default");
    }
    //yield getAllReports()
    transformMixPanelData(EVENTS.default_report_set, {});
  } catch (error) {
    yield put({
      type: SET_DEFAULT_REPORT_FAILURE,
    });
    if (args?.payload?.loading) {
      yield args.payload.loading("Something went wrong, Try again later");
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(SHARE_REPORT, shareReport),
    takeLatest(GET_SHARED_REPORT, getSharedReportDetails),
    takeLatest(CREATE_REPORT, createReport),
    takeLatest(UPDATE_REPORT, updateReport),
    takeLatest(UPDATE_REPORT_DATA, updateReportData),
    takeLatest(ADD_REPORT_DATA, addReportData),
    takeLatest(DELETE_REPORT, deleteReport),
    takeLatest(GET_ALL_REPORTS, getAllReports),
    takeLatest(GET_REPORT, getReport),
    takeLatest(UPDATE_CHART, updateChart),
    takeLatest(UPDATE_GRID, updateGrid),
    takeEvery(UPDATE_TAB, updateTab),
    takeLatest(DUPLICATE_REPORT, duplicateReport),
    takeLatest(SET_DEFAULT_REPORT, setDefaultReport),
    takeLatest(GET_DEFAULT_REPORT, getDefaultReport),
    takeLatest(GET_TAB_DETAILS, getTab),
  ]);
}
