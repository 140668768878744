import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Box, CssBaseline, Typography, } from "@mui/material";
import ReportPage from "./ReportPage";
import { Report } from "../../models/report";
import { getReport, updateReportAdAccCols, getSharedReportDetails } from "../../api/report/action";
import { blendsList } from "../../api/blends/action";
import DashboardLoader from "../Common/DashboardLoader";
import { ReactComponent as NoReports } from "../../Assets/errorMsgImages/NoAuditData.svg";
import "./dashboard.css"
import { useMixpanelSessionTime } from "../../utils/useMixpanelSessionTime";
import { EVENTS,handleMixPanelData,mixpanel_obj } from "../../utils/mixpanelV2";

function ShareReport({ allReports, reports, reportDownloaded, getReport, blendsList, updateReportAdAccCols }) {

    const dispatch = useDispatch();
    const { shareReportId } = useParams();
    const { reportId, deleted, expired, failed } = useSelector(state => state.report.sharedReports[shareReportId]) ?? {};

    const [report, setReport] = React.useState({});
    const [tabId, setTabId] = useState(null);
    const [loaded, setLoaded] = React.useState({
        blends: false,
    });

    useEffect(() => {
        dispatch(getSharedReportDetails({
            shareReportId, callback: (tab_id) => {
                setTabId(tab_id);
            }
        }))
    }, [])

    useEffect(() => {
        if (reportDownloaded && reportId && allReports?.length) {
            const foundReport = allReports?.find(report => report.id === +reportId);
            let reportObj = Report.fromJSON({ ...foundReport, tabs: reports[reportId] ?? {} });
            if (reportObj) {
                setReport(reportObj);
            } else {
                setLoaded(prev => ({ ...prev, tabDetails: false }))
                getReport({
                    id: reportId, callback: (tabId) => {
                        setTabId(tabId);
                        setLoaded(prev => ({ ...prev, tabDetails: true }))
                    }
                });
            }
        }
    }, [reportId, allReports, tabId, reports[reportId]]);

    useEffect(() => {
        setLoaded(prev => ({ ...prev, blends: false }))
        updateReportAdAccCols({ reset: true })
    }, [reportId])

    useEffect(() => {
        if (!loaded.blends && report.adAccounts) {
            blendsList({
                params: report.adAccounts?.length ? { audiences: report.adAccounts.map(acc => ({ type: "AD_ACCOUNT", id: acc.id ?? acc.account_id, dataSource: acc.channelType ?? acc.source })) } : {},
                update: () => setLoaded((prev) => ({ ...prev, blends: true })),
            });
        }
    }, [report.adAccounts])

    const sessionCallback = (report) => {
        handleMixPanelData(EVENTS.view_shared_report, { report_name: report.report_name, session_time: totalSessionTime });
    }
    const { totalSessionTime } = useMixpanelSessionTime(report,sessionCallback);
    useEffect(() => {
      if(report && totalSessionTime > 0){
        sessionCallback(report);
      }
    },[totalSessionTime,report]);
    useEffect(() => {
        mixpanel_obj.trackTime(EVENTS.view_shared_report)
    },[]);
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {(deleted || expired || failed)
                ? <div className="displayColCenter" style={{ width: "100vw", height: "100vh" }}>
                    <NoReports style={{ width: "50%" }} />
                    <Typography style={{ fontSize: "16px", fontWeight: 600 }}>
                        No Report Available
                    </Typography>
                    <Typography style={{ color: "#5C6674", marginTop: "8px" }}>
                        {expired
                            ? "Access to the shared report is unavailable. Please contact to your Org. Admin"
                            : deleted
                                ? "This report has been deleted by your organization."
                                : "Invalid share link."
                        }
                    </Typography>
                </div>

                : (!reportDownloaded || !Object.keys(report).length || !Object.values(loaded).every((val) => val))
                    ? <DashboardLoader />
                    : <ReportPage
                        tabId={tabId} setTabId={setTabId} reportId={reportId}
                        report={report} setReport={setReport}
                        shared={true}
                    />
            }
        </Box >
    );
}

const mapStateToProps = (state) => ({
    allReports: state.report.allReports,
    reports: state.report.reports,
    reportDownloaded: state.report.reportDownloaded,
});

const mapDispatchToProps = (dispatch) => ({
    getReport: (_) => dispatch(getReport(_)),
    updateReportAdAccCols: (_) => dispatch(updateReportAdAccCols(_)),
    blendsList: (_) => dispatch(blendsList(_)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareReport);