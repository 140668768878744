import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, CssBaseline, } from "@mui/material";
import { connect } from "react-redux";
import { checkAuth, getAllCustomColumns } from "../../api/channel/action";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { sendFingerprint } from "../../api/auth/saga";
import { getUser, } from "../../api/user/action";
import { Report, Tab as ReportTab, Grid as ReportGrid, } from "../../models/report";
import { getReport, clearReportUpdates, updateReportAdAccCols } from "../../api/report/action";
import { blendsList } from "../../api/blends/action";
import ProductTourModal from "../Modal/ProductTourModal";
import IntroTour from "../Common/IntroTour";
import DashboardLoader from "../Common/DashboardLoader";
import LeftBar from "../LeftBar/LeftBar";
import ReportPage from "./ReportPage";
import "./dashboard.css"

// const DrawerHeader = styled("div")(() => ({
//   display: "flex",
//   width: "100%",
//   height: "100px",
// }));

function Dashboard({
  allReports, reports, updates, reportDownloaded, getReport, clearReportUpdates, updateReportAdAccCols,
  checkAuth, getUser, blendsList, getAllCustomColumns,
}) {
  const history = useHistory();

  const query = new URLSearchParams(window.location.search);
  const reportId = query.get('report_id'); // this is id in report, not report_id
  const reportName = query.get('report_name');
  const tabId = query.get('tab_id');
  const tabName = query.get('tab_name');

  const [startTour, setStartTour] = React.useState(false);
  const [report, setReport] = React.useState({});
  const [callbackRedirection, setCallbackRedirection] = useState(null);
  const [unsavedModal, setUnsavedModal] = React.useState({ open: false });
  const [loaded, setLoaded] = React.useState({
    blends: false,
    customCols: false,
    tabDetails: false
  });

  const addEvent = () => {
    window.history.pushState('', '', window.location.href);
  };

  useEffect(() => {
    const handleBackButton = (event) => {
      // Preventing default behavior of the back button
      event.stopPropagation();
      event.preventDefault();
      if (updates.length) {
        setCallbackRedirection(() => () => history.push('/report-management'));
        handleUnsavedChangesClick();
      } else {
        window.history.back();
      }
    };

    // Adding event listener to the window for the back button
    window.addEventListener('popstate', handleBackButton);
    addEvent();

    // Cleaning up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [updates]);

  useEffect(() => {
    if (reportDownloaded && reportId && allReports?.length) {
      const foundReport = allReports?.find(report => report.id === +reportId);
      // let reportJSON = Report.new(foundReport.report_name, foundReport.report_icon, foundReport.associated_accounts);
      let reportObj = Report.fromJSON({ ...foundReport, tabs: reports[reportId] ?? {} });
      if (reportObj) {
        setReport(reportObj);
      } else {
        setLoaded(prev => ({ ...prev, tabDetails: false }))
        getReport({
          id: reportId, callback: (tabId, tabName) => {
            const params = new URLSearchParams({ report_id: reportId, report_name: reportName, tab_id: tabId, tab_name: tabName });
            history.replace({ search: params.toString() });
            setLoaded(prev => ({ ...prev, tabDetails: true }))
          }
        });
      }
    }
  }, [reportId, allReports, tabId, reports[reportId],reportDownloaded]);

  useEffect(() => {
    if (reportId && tabId && !reports[reportId]) {
      history.push('/report-management')
    }
  }, [tabId, reports[reportId]]);

  const { data: visitorData } = useVisitorData();

  const { showTutorial, run } = useSelector((state) => state.user.tutorial) || {};

  useEffect(() => {
    if (visitorData) {
      sendFingerprint(visitorData, visitorData.visitorId);
    }
  }, [visitorData]);

  useEffect(() => {
    setLoaded(prev => ({ ...prev, blends: false }))
    updateReportAdAccCols({ reset: true })
  }, [reportId])
  

  useEffect(() => {
    if (!loaded.blends && report.adAccounts) {
      blendsList({
        params: report.adAccounts?.length ? { audiences: report.adAccounts.map(acc => ({ type: "AD_ACCOUNT", id: acc.id, dataSource: acc.channelType })) } : {},
        update: () => setLoaded((prev) => ({ ...prev, blends: true })),
      });
    }
  }, [report.adAccounts])

  useEffect(() => {
    if (reportId) {
      setLoaded({ ...loaded, customCols: false, tabDetails: false });
      checkAuth();
      getUser();
      getReport({
        id: reportId, callback: (tab_id, tab_name) => {
          if (!tabId) {
            const params = new URLSearchParams({ report_id: reportId, report_name: reportName, tab_id: tab_id, tab_name: tab_name });
            history.replace({ search: params.toString() });
          }
          setLoaded((prev) => ({ ...prev, tabDetails: true }))
        }
      });
      getAllCustomColumns({
        update: () => setLoaded((prev) => ({ ...prev, customCols: true })),
      });
    }

    return () => {
      clearReportUpdates();
    }
  }, []);

  const handleUnsavedChangesClick = () => {
    setUnsavedModal({ open: true });
  };

  const navigationCallback = (callback) => {
    if (updates.length > 0) {
      setCallbackRedirection(() => callback);
      handleUnsavedChangesClick();
    } else {
      callback?.();
    }
  };

  return (
    <>
      {/* {isReportUpdated && showTutorial && (
        <IntroTour
          editDrawerState={editDrawerState}
          setEditDrawerState={setEditDrawerState}
          chart={report.tabs[0].grids[0].charts[0]}
          source={"dashboard"}
        />
      )} */}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* <AppBar open={editDrawerState.isOpen} drawerWidth={drawerWidth} /> */}
        <LeftBar navigationCallback={navigationCallback} />
        {(!reportDownloaded || !Object.keys(report).length || !Object.values(loaded).every((val) => val))
          ? (
            <DashboardLoader />
          ) : (
            <ReportPage
              tabId={tabId} reportId={reportId}
              report={report} setReport={setReport} callbackRedirection={callbackRedirection}
              unsavedModal={unsavedModal} setUnsavedModal={setUnsavedModal}
              setCallbackRedirection={setCallbackRedirection} navWidth={100} />
          )}
        {/* <ProductTourModal
          key={startTour}
          modal={startTour}
          setModal={setStartTour}
        /> */}
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  allReports: state.report.allReports,
  reports: state.report.reports,
  updates: state.report.updates,
  reportDownloaded: state.report.reportDownloaded,
});

const mapDispatchToProps = (dispatch) => ({
  checkAuth: () => dispatch(checkAuth()),
  getUser: () => dispatch(getUser()),
  getReport: (_) => dispatch(getReport(_)),
  clearReportUpdates: (_) => dispatch(clearReportUpdates(_)),
  updateReportAdAccCols: (_) => dispatch(updateReportAdAccCols(_)),
  blendsList: (_) => dispatch(blendsList(_)),
  getAllCustomColumns: (_) => dispatch(getAllCustomColumns(_)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
// use on pop state