import mixpanel from "mixpanel-browser";
import { ChannelType } from "../models/channel";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN_DEV;
const parse = () => {
  try{
    const obj = JSON.parse(localStorage.getItem("userInfo") || {}) ?? {};
    return obj;
  }catch(err){
    return {};
  }
};

class Mixpanel {
  instance = null;
  constructor() {
    if (!this.instance) {
      this.instance = mixpanel;
      mixpanel.init(MIXPANEL_TOKEN, { debug: true });
    }
  }
  track(event, data, extra = {}) {
    const other = extra
      ? { email_id: parse().email, organisation_name: parse().company_name }
      : {};
    mixpanel.track(event, { ...data, ...other });
  }
  login_success(email_id) {
    mixpanel.identify(email_id);
    mixpanel.people.set({
      $email: email_id,
      $name: email_id,
    });
  }
  trackTime(event){
    mixpanel.time_event(event);
  }
}

export const mixpanel_obj = new Mixpanel();

export const EVENTS = {
  sign_in_google: "sign_in_google",
  sign_in_cus: "sign_in_cus",
  sign_in_fb: "sign_in_fb",
  sign_up_fb: "sign_up_fb",
  sign_up_google: "sign_up_google",
  sign_up_cus: "sign_up_cus",
  choose_data_source: "choose_data_source",
  connect_ad_account: "connect_ad_account",
  view_data_sources: "view_data_sources",
  click_data_sources: "click_data_sources",
  click_left_panel: "click_left_panel",
  click_blends: "click_blends",
  click_custom_col: "click_custom_col",
  click_extractor: "click_extractor",
  view_blends_page: "view_blends_page",
  create_custom_blends: "create_custom_blends",
  create_default_blend: "create_default_blend",
  template_default_blend: "template_default_blend",
  click_use_template_blends: "click_use_template_blends",
  click_or_update_blends: "click_or_update_blends",
  save_new_blend: "save_new_blend",
  update_blends: "update_blends",
  edit_blends: "edit_blends",
  view_custom_col: "view_custom_col",
  click_custom_metric: "click_custom_metric",
  click_custom_dimension: "click_custom_dimension",
  save_custom_metric: "save_custom_metric",
  update_custom_metric: "update_custom_metric",
  edit_custom_metric: "edit_custom_metric",
  success_save_custom_metric: "success_save_custom_metric",
  click_invite_team: "click_invite_team",
  success_invite_team: "success_invite_team",
  create_trigger: "create_trigger",
  success_create_triggers: "success_create_triggers",
  update_trigger: "update_trigger",
  delete_trigger: "delete_trigger",

  view_reports_mg: "view_reports_mg",
  click_search_reports_mg: "click_search_reports_mg",
  click_3_dots_reports_mg: "click_3_dots_reports_mg",
  click_view_reports: "click_view_reports",
  Click_create_report_rm: "Click_create_report_rm",
  click_filterds_create_report: "click_filterds_create_report",
  click_ad_accounts: "click_ad_accounts",
  click_create_report_rm: "click_create_report_rm",
  view_success_create_report: "view_success_create_report",
  view_report_report: "view_report_report",
  click_share_rm: "click_share_rm",
  click_calendar_report: "click_calendar_report",
  update_calendar_report: "update_calendar_report",
  compare_calendar_reports: "compare_calendar_reports",
  add_tab_report: "add_tab_report",//doubt
  click_share_report: "click_share_report",
  click_share_cta: "click_share_cta",
  success_share_report: "success_share_report", //pending
  add_chart: "add_chart", //pending
  click_save_report: "click_save_report",

  add_sources_reports: "add_sources_reports",
  add_sources_rm: "add_sources_rm",
  view_add_sources: "view_add_sources",
  Update_add_sources: "Update_add_sources",
  click_edit_widget: "click_edit_widget",

  chart_type_edit_widget: "chart_type_edit_widget",
  ad_acc_edit_widget: "ad_acc_edit_widget",
  handle_chart: "handle_chart",
  platform_edit_widget: "platform_edit_widget",
  success_create_blends: "success_create_blends",
  click_connect_account: "click_connect_account",
  view_shared_report: "view_shared_report",
  click_copy_url: "click_copy_url",
  edit_data_shared_report: "edit_data_shared_report",
  apply_calendar_shared_report: "apply_calendar_shared_report"
};

export const handleMixPanelData = (type, data = {}) => {
  switch (type) {
    case EVENTS.sign_in_cus:
      handleAuth(type, data);
      break;
    case EVENTS.sign_in_google:
      handleAuth(type, data);
      break;
    case EVENTS.sign_up_cus:
      handleAuth(type, data);
      break;
    case EVENTS.sign_up_fb:
      handleAuth(type, data);
      break;
    case EVENTS.sign_up_google:
      handleAuth(type, data);
      break;
    case EVENTS.choose_data_source:
      handleChooseDataSource(type, data);
      break;
    case EVENTS.connect_ad_account:
      handleConnectDataSource(type, data);
      break;
    case EVENTS.view_data_sources:
      handleViewDataSource(type, data);
      break;
    case EVENTS.click_data_sources:
      handleClickDataSource(type, data);
      break;
    case EVENTS.click_left_panel:
      handleLeftNavbar(type, data);
      break;
    case EVENTS.view_blends_page:
      handleViewBlendPage(type, data);
      break;
    case EVENTS.create_default_blend:
      handleCreateBlend(type, data);
      break;
    case EVENTS.create_custom_blends:
      handleCreateBlend(type, data);
      break;
    case EVENTS.template_default_blend:
      handleTemplateDefaultBlend(type, data);
      break;
    case EVENTS.click_use_template_blends:
      handleClickUseTemplatesBlend(type, data);
      break;
    case EVENTS.click_or_update_blends:
      handleClickOrUpdateBlends(type, data);
      break;
    case EVENTS.edit_blends:
      handleEditBlends(type, data);
      break;
    case EVENTS.view_custom_col:
      handleViewCustomCol(type, data);
      break;
    case EVENTS.click_custom_metric:
      handleCustomMetricClick(type, data);
      break;
    case EVENTS.click_custom_dimension:
      handleCustomMetricClick(type, data);
      break;
    case EVENTS.save_custom_metric:
      handleSaveCustomMetric(type, data);
      break;
    case EVENTS.update_custom_metric:
      handleSaveCustomMetric(type, data);
      break;
    case EVENTS.edit_custom_metric:
      handleCustomMetricClick(type, data);
      break;
    case EVENTS.success_save_custom_metric:
      handleSaveCustomMetric(type, data);
      break;
    case EVENTS.click_invite_team:
    case EVENTS.success_invite_team:
      mixpanel_obj.track(type,{ ...data },true);
      break;
    case EVENTS.create_trigger:
    case EVENTS.success_create_triggers:
    case EVENTS.update_trigger:
      handleTrigger(type, data);
      break;
    case EVENTS.delete_trigger:
      mixpanel_obj.track(type,{ ...data },true);
      break;
    case EVENTS.click_filterds_create_report:
      handleClickFilteredCreateReport(type, data);
      break;
    case EVENTS.view_success_create_report:
      handleSuccessCreateReport(type, data);
      break;
    case EVENTS.view_reports_mg:
    case EVENTS.click_search_reports_mg:
    case EVENTS.click_3_dots_reports_mg:
    case EVENTS.click_view_reports:
    case EVENTS.Click_create_report_rm:
    case EVENTS.click_create_report_rm:
    case EVENTS.click_share_cta:
      handleGlobal(type, data);
      break;
    case EVENTS.update_calendar_report:
    case EVENTS.compare_calendar_reports:
    case EVENTS.click_calendar_report:
    case EVENTS.click_share_report:
    case EVENTS.click_share_rm:
    case EVENTS.add_sources_rm:
    case EVENTS.add_sources_reports:
    case EVENTS.view_add_sources:
    case EVENTS.click_edit_widget:
    case EVENTS.add_tab_report:
      shareRreport(type, data);
      break;
    case EVENTS.click_save_report:
      saveReport(type, data);
      break;
    case EVENTS.Update_add_sources:
      handleUpdateAddSources(type, data);
      break;
    case EVENTS.chart_type_edit_widget:
      handleChart(type, data);
      handleChart(EVENTS.ad_acc_edit_widget, data);
      handleChart(EVENTS.platform_edit_widget, data)
      break;
    case EVENTS.view_report_report:
      handleViewReport(type, data);
      break;
    case EVENTS.click_connect_account:
      handleGlobal(type, data);
      break;
    case EVENTS.view_shared_report:
      handleViewSharedReport(type, data);
      break;
    case EVENTS.edit_data_shared_report:
      handleEditDataSharedReport(type, data);
      break;
    case EVENTS.apply_calendar_shared_report:
      handleSharedDateRange(type, data);
      break;
  }
};

const handleAuth = (type, data) => {
  mixpanel_obj.track(type,{ ...data },false);
};

const handleChooseDataSource = (type, data) => {
  mixpanel_obj.track(type,{ ...data },true);
}

const handleConnectDataSource = (type, data) => {
  mixpanel_obj.track(type,{ ...data },true);
}

const handleViewDataSource = (type, data = {}) => {
  mixpanel_obj.track(type, data,true);
}

const handleClickDataSource = (type, data) => {
  mixpanel_obj.track(type, data,true);
}

const handleLeftNavbar = (type, data) => {
  const mapped = {
    "datablending": EVENTS.click_blends,
    "pixisextractor": EVENTS.click_extractor,
    "customcolumn": EVENTS.click_custom_col
  }
  mapped[data.menuId] && mixpanel_obj.track(mapped[data.menuId], {},true);
}

const handleViewBlendPage = (type, data) => {
  mixpanel_obj.track(type, data,true);
}

const handleCreateBlend = (type, data) => {
  mixpanel_obj.track(type, data,true);
}

const handleTemplateDefaultBlend = (type, data) => {
  const platforms = data?.dsConfigs?.map(d => d?.dataSource) ?? [];
  mixpanel_obj.track(type, { platforms }, true);
}

const handleClickUseTemplatesBlend = (type, data) => {
  const platforms = data?.dsConfigs?.map(d => d?.dataSource) ?? [];
  mixpanel_obj.track(type, { platforms }, true);
}

const channel_mapped_account_key = {
  "google-ads": "customerId",
  "shopify-ads": "storeName"
}

const handleClickOrUpdateBlends = (type, data) => {
  let [platforms, ad_accounts] = [ [], [] ];
  data?.newSourceData?.dataSources?.forEach(a => { 
    const accountKey = channel_mapped_account_key[a?.account?.channelType];
    a?.account?.channelType && platforms.push(a?.account?.channelType); 
    accountKey ? ad_accounts.push(a?.account?.[accountKey]) : ad_accounts.push(a?.account?.accountId);
  });
  if(data?.isUpdate){
    mixpanel_obj.track(EVENTS.update_blends, { platforms,ad_accounts }, true);
  }else{
    mixpanel_obj.track(EVENTS.save_new_blend, { platforms,ad_accounts }, true);
  }
}

const handleEditBlends = (type, data) => {
  mixpanel_obj.track(type, data, true);
}

const handleViewCustomCol = (type, data) => {
  mixpanel_obj.track(type, data, true);
}

const handleCustomMetricClick = (type, data) => {
  mixpanel_obj.track(type, data, true);
}

const handleSaveCustomMetric = (type,data) => {
  const [ ad_accounts, platforms ] = [ [], [] ];
  data?.audiences?.forEach(a => {
    if(channel_mapped_account_key[a?.dataSource]){
      ad_accounts.push(a?.metaData[channel_mapped_account_key[a?.dataSource]]);
    }else {
      ad_accounts.push(a?.metaData?.accountId);
    }
    platforms.push(a?.dataSource);
  });
  mixpanel_obj.track(type, { ad_accounts, platforms }, true);
}

const handleTrigger = (type, data) => {
  let [ ad_accounts, platforms ] = [ [], [data?.dataSource] ];
  ad_accounts = data?.accounts?.map(a => channel_mapped_account_key[data?.dataSource] ? a[channel_mapped_account_key[data?.dataSource]] : a.accountId);
  mixpanel_obj.track(type, { ad_accounts, platforms }, true);
}

const handleGlobal = (type, data) => {
  mixpanel_obj.track(type,{ ...data },true);
}

const handleClickFilteredCreateReport = (type, data) => {
  const { platform } = data;
  mixpanel_obj.track(type,{ platform },true);
}

const handleSuccessCreateReport = (type, data) => {
  let [ ad_accounts, platforms ] = [ [], [] ];
  data?.adAccounts?.forEach(a => { ad_accounts.push(a?.id); platforms.push(a?.dataSource)});
  mixpanel_obj.track(type,{ ad_accounts, platforms },true);
}

const shareRreport = (type, data) => {
  const { report_name } = data;
  mixpanel_obj.track(type,{ report_name },true);
}

const saveReport = (type, data) => {
  let [ report_name, ad_accounts, platforms ] = [ data?.report_name ?? "", [], [] ];
  data?.adAccounts?.forEach(a => { ad_accounts.push(a?.account_id); platforms.push(a?.source) });
  mixpanel_obj.track(type,{ report_name,ad_accounts, platforms },true);
}

const handleUpdateAddSources = (type, data) => {
  let [ report_name, ad_accounts, platforms ] = [ data?.report_name ?? "", [], [] ];
  data?.adAccounts?.forEach(a => { ad_accounts.push(a?.id); platforms.push(a?.metaData?.source ?? a?.metaData?.channelType) });
  mixpanel_obj.track(type,{ report_name,ad_accounts, platforms },true);
}

const handleChart = (type, data)  => {
  const { report_name,accounts,channelType } = data;
  if(type === EVENTS.chart_type_edit_widget){
    mixpanel_obj.track(type,{ report_name, chart_type: data?.type },true);
  }else if(type === EVENTS.ad_acc_edit_widget){
    let [ ad_accounts, platform ] = [  accounts, channelType ];
    mixpanel_obj.track(type,{ report_name,ad_accounts,platform },true);
  }else if(type === EVENTS.platform_edit_widget){
    let [ platform ] = [  channelType ];
    mixpanel_obj.track(type,{ report_name,platform },true);
  }
}

const handleViewReport = (type, data) => {
  let [ ad_accounts, platforms ] = [ [], [] ];
  data?.adAccounts?.forEach(a => { ad_accounts.push(a?.id); platforms.push(a?.source)});
  mixpanel_obj.track(type,{ ad_accounts, platforms },true);
}

const handleViewSharedReport = (type, data) => {
  const { report_name,session_time } = data;
  mixpanel_obj.track(type,{ report_name,session_time },true);
}

const handleEditDataSharedReport = (type, data) => {
  const { report,chart } = data;
  mixpanel_obj.track(type,{ report_name: report, chart_type: chart.type, 
                            dataSource: chart?.account?.channelType ?? chart?.channelType, ad_account_name: chart?.account?.title, 
                            dimensions: chart.dimensions,filter: chart.filter,
                            columns: chart?.leftMetrics?.concat(chart?.rightMetrics ?? [])
                          },true);
}

const handleSharedDateRange = (type, data) => {
  const { report_name,calendar_range } = data;
  mixpanel_obj.track(type,{ report_name, calendar_range },true);
}
